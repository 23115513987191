import {BaseService} from "./base.service";

class SpecificationsHeadersService extends BaseService {

    constructor() {
        super('api/specifications-headers')
    }

    updateOrder(params) {
        return this.http.post('api/update-order-specification-headers', params).then(response => response.data).catch(err => err.message);
    }

    filterTrash() {
        return this.http.get('api/get-trash-specification-headers').then(response => response.data).catch(err => err.message);
    }

    restoreTrash(id) {
        return this.http.get(`api/restore-trash-specification-headers/${id}`).then(response => response.data).catch(err => err.message);
    }

}

export const specificationsHeadersService = new SpecificationsHeadersService();
