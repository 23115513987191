import {BaseService} from "./base.service";

class HomeSliderService extends BaseService {

    constructor() {
        super('api/home-slider')
    }

    updateOrder(params) {
        return this.http.post('api/update-order-slider', params).then(response => response.data).catch(err => err.message);
    }

}

export const homeSliderService = new HomeSliderService();
