import {BaseService} from "./base.service";

class TagsService extends BaseService {

    constructor() {
        super('api/tags')
    }

}

export const tagsService = new TagsService();
