<script setup>
import {ordersService} from "../../../services/Orders.service";
import {onMounted, toRef} from "vue";
import {gralHook} from "../../../Hooks/gralHook";

const {getAlert} = gralHook();
const props = defineProps({
    admin_notes: {
        type: [String],
        default: null,
    },
    client_notes: {
        type: [String],
        default: null,
    },
    orderId: {
        type: [Number],
        default: null,
    },
});

const admin_notes = toRef(props, 'admin_notes');

const client_notes = toRef(props, 'client_notes');

const order_id = toRef(props, 'orderId');

const saveAdminNote = async () => {
    let resp = await ordersService.saveAdminNote(order_id.value, {'admin_note': admin_notes.value});
    getAlert(resp.process, true);
};

</script>

<template>
    <!-- begin::modal EDIT Comments-->
    <div id="comments" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Comentarios de la Orden</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <!--  start card -->

                    <!-- begin::form-->
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label class="ul-form__label" for="">Comentarios del Cliente:</label>
                                <textarea rows="3" class="form-control" disabled>{{client_notes}}</textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label class="ul-form__label" for="">Comentarios Internos:</label>
                                <textarea v-model="admin_notes" rows="3" class="form-control"
                                          placeholder="¿Tienes algún comentario para esta orden?"></textarea>

                            </div>
                        </div>
                    </form>
                    <!--  end::form -->
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                    <button class="btn btn-success ml-2" type="button" @click="saveAdminNote()">Añadir</button>
                </div>
            </div>
        </div>
    </div>
    <!-- end::modal EDIT Comments-->
</template>

<style scoped>

</style>
