import {BaseService} from "./base.service";

class SettingInfosService extends BaseService {
    constructor() {
        super('api/settings-infos');
    }

    getListInfos() {
        return this.http.get(`api/get-settings-infos`).then(response => response.data).catch(err => err.message);
    }
}

export const settingInfosService = new SettingInfosService();
