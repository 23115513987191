<script setup>
import {ref, onMounted, watchEffect, computed} from 'vue';
import {settingsService} from '../../../services/Settings.service.js';
import {gralHook} from "../../../Hooks/gralHook";
import {specificationsService} from "../../../services/Specifications.service";
import {apiKeysService} from "../../../services/Apikeys.service";
import Quill from 'quill';
import "quill/dist/quill.snow.css";

let quill = null;
const {getAlert} = gralHook();
const settings = ref({
    enable_maintenance: {
        value: false // valor por defecto
    }
});
const apiKeys = ref({});
const specifications = ref([]);
const selectedPaymentOption = ref(0);
const isMaintenanceEnabled = ref(false);

onMounted(async () => {

    quill = new Quill("#editor", {
        theme: "snow",
    });

    specifications.value = await specificationsService.getMany();
    const settingsArray = await settingsService.getMany();
    settings.value = settingsArray.reduce((obj, setting) => {
        obj[setting.key] = {id: setting.id, value: setting.value};
        return obj;
    }, {});

    //LLENAMOS EL RICHT TEXT EDITOR
    if (settings.value.enable_maintenance_message) {
        isMaintenanceEnabled.value = settings.value.enable_maintenance.value;
        quill.root.innerHTML = settings.value.enable_maintenance_message.value;
    }

    // Convierte settings.enable_maintenance.value a un booleano
    if (settings.value.enable_maintenance) {
        //console.log('checando el valor de enable_maintenance', settings.value.enable_maintenance.value)
        settings.value.enable_maintenance.value = settings.value.enable_maintenance.value == 1;
        //console.log('checando el valor de enable_maintenance despues de asignacion', settings.value.enable_maintenance.value)
    }

    const apyKeysArray = await apiKeysService.getMany();
    apiKeys.value = apyKeysArray.reduce((obj, apikey) => {
        obj[apikey.name] = {id: apikey.id, value: apikey.value};
        return obj;
    }, {});

    //await Vue.nextTick();

});

const toggleMaintenance = () => {
    console.log('toggleMaintenance=', settings.value.enable_maintenance.value);
    settings.value.enable_maintenance.value = settings.value.enable_maintenance.value == 1 ? 0 : 1;
};

const updateSettings = async (formId) => {
    const form = document.getElementById(formId);
    const formData = new FormData(form);

    if (formId === 'formMaintenance') {
        formData.append('enable_maintenance_message', quill.root.innerHTML);
        //formData.append('enable_maintenance', settings.value.enable_maintenance.value);
    }

    for (let [key, value] of formData.entries()) {
        console.log('for - ', key, value)
        switch (key) {
            case 'payment_option':
                const openpayId = settings.value['enable_openpay'].id;
                const clipId = settings.value['enable_clip_payment'].id;
                if (value === 'openpay') {
                    await settingsService.updateOne(openpayId, {value: 1});
                    await settingsService.updateOne(clipId, {value: 0});
                } else if (value === 'clip') {
                    await settingsService.updateOne(openpayId, {value: 0});
                    await settingsService.updateOne(clipId, {value: 1});
                }
                break;
            case 'enable_maintenance_message':
                if (settings.value[key]) {
                    const id = settings.value[key].id;
                    await settingsService.updateOne(id, {value});
                }
                break;
            default:
                if (settings.value[key]) {
                    const id = settings.value[key].id;
                    await settingsService.updateOne(id, {value});
                } else {
                    console.warn(`No setting found for key: ${key}`);
                }
        }
    }
    getAlert('updated');
};

watchEffect(() => {
    if (settings.value.enable_openpay && settings.value.enable_openpay.value === "1") {
        selectedPaymentOption.value = 'openpay';
    } else if (settings.value.enable_clip_payment && settings.value.enable_clip_payment.value === "1") {
        selectedPaymentOption.value = 'clip';
    } else {
        selectedPaymentOption.value = 0;
    }
});

</script>

<template>
    <div>
        <!-- begin::modal EDIT Comments-->
        <div id="mantto" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Mensaje de Mantenimiento</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="formMaintenance">
                            <div class="form-group" style="width: 150px">
                                <div v-if="settings && settings.enable_maintenance">
                                    <strong>Activar mantenimiento</strong>
                                    <!-- <input type="checkbox" style="visibility:hidden" name="enable_maintenance"
                                           id="enable_maintenance"
                                           v-if="settings && settings.enable_maintenance"
                                           v-model="settings.enable_maintenance.value"
                                           @change="toggleMaintenance">-->
                                    <input type="checkbox" style="visibility:hidden" name="enable_maintenance"
                                           id="enable_maintenance"
                                           v-if="settings && settings.enable_maintenance"
                                           :checked="settings.enable_maintenance.value == 1"
                                           @change="toggleMaintenance">
                                    <label class="switch" for="enable_maintenance"></label>
                                </div>
                                <div v-else>
                                    Cargando...
                                </div>
                            </div>
                            <div id="editor" class="mb-4"></div>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2"
                                type="button"
                                @click="updateSettings('formMaintenance')">
                            Añadir
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal EDIT Comments-->
        <!-- ============ Body content start ============= -->
        <div class="main-content">
            <div class="breadcrumb">
                <h1 class="mr-2">Configuración Inicial</h1>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

            <!-- BOTÓN CERRAR VENTANA --->
            <div class="card-header text-right bg-transparent">
                <button class="btn btn-warning btn-md m-1"
                        type="button"
                        data-toggle="modal"
                        v-if="$hasPermission('maintenance.view')"
                        data-target="#mantto">
                    <i class="fa fa-wrench mr-2"></i>
                    {{ settings.enable_maintenance?.value == 1 ? 'Salir de Mantenimiento' : 'Activar Mantenimiento' }}
                </button>
                <button class="btn btn-danger btn-md m-1" type="button" onclick=""><i
                    class="fa fa-angle-left text-white mr-2"></i> Regresar
                </button>
            </div>
            <section>
                <div>
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <div class="card-body">
                                <div class="card-title mb-3"><i class="fa fa-store"></i> Información General</div>
                                <form id="form1">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Nombre Comercial:</label>
                                            <!--- business_name --->
                                            <input class="form-control" v-if="settings.business_name"
                                                   v-model="settings.business_name.value" name="business_name"
                                                   type="text"
                                                   placeholder="Nombre de la Marca"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Razón Social:</label>
                                            <!--- trade_name --->
                                            <input class="form-control" v-if="settings.trade_name"
                                                   v-model="settings.trade_name.value" name="trade_name" type="text"
                                                   placeholder="Nombre de la Persona Física o Moral"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Horario:</label><!--- schedule --->
                                            <input class="form-control" v-if="settings.schedule"
                                                   v-model="settings.schedule.value" name="schedule" type="text"
                                                   placeholder="Texto libre"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Dirección: </label><!--- address --->
                                            <input class="form-control" v-if="settings.address"
                                                   v-model="settings.address.value" name="address" type="text"
                                                   placeholder="Texto libre"/>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">Mapa de Google: </label>
                                            <!--- url_map --->
                                            <small class="text-danger"> Selecciona tu ubicación en <a
                                                href="http://maps.google.com/">Google Maps,</a> da click en el botón de
                                                compartir, selecciona la pestaña de "Incorporar un Mapa" y copia el
                                                código HTML que te muestra Google Maps. </small>
                                            <input class="form-control" name="google_map" type="text"
                                                   v-if="settings.url_map"
                                                   v-model="settings.url_map.value"
                                                   placeholder="<iframe> .... </iframe>"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Referencias de la Dirección:</label>
                                            <!--- contact_reference --->
                                            <input class="form-control" name="contact_reference"
                                                   v-if="settings.contact_reference"
                                                   v-model="settings.contact_reference.value" type="text"
                                                   placeholder="Texto libre"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Correo Electrónico del Área de
                                                Ventas:</label><!--- mailjet_cc_email --->
                                            <input class="form-control" name="mailjet_cc_email" type="text"
                                                   v-if="settings.mailjet_cc_email"
                                                   v-model="settings.mailjet_cc_email.value"
                                                   placeholder="Aquí llegarán Notificaciones de nuevos pedidos"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Nombre del Remitente en Confirmación de
                                                Pedidos:</label><!--- mailjet_cc_name --->
                                            <input class="form-control" name="mailjet_cc_name" type="text"
                                                   v-if="settings.mailjet_cc_name"
                                                   v-model="settings.mailjet_cc_name.value"
                                                   placeholder="Identificador del Correo Electrónico que se mostrará a los clientes"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">ID Plantilla Mailjet (Diseño de
                                                Plantilla para Confirmación de Pedidos):</label>
                                            <!--- mailjet_template_id --->
                                            <input class="form-control" name="mailjet_template_id" type="text"
                                                   v-if="settings.mailjet_template_id"
                                                   v-model="settings.mailjet_template_id.value"
                                                   placeholder="Diseño del Correo Electrónico que se mostrará a los clientes"/>
                                        </div>
                                    </div>
                                    <div class="modal-footer my-4"><!--- Línea División-->
                                        <button class="btn btn-success ml-2" type="button"
                                                v-if="$hasPermission('general_info.udpate')"
                                                @click="updateSettings('form1')">
                                            <i class="fa fa-tag text-white mr-2"></i> Actualizar
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div class="card-body">
                                <div class="card-title mb-3"><i class="fa fa-phone"></i> Contacto con la Tienda</div>
                                <form id="form2">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Teléfono del Encabezado:</label>
                                            <!--- phone_header --->
                                            <input class="form-control" name="phone_header" type="tel"
                                                   v-if="settings.phone_header"
                                                   v-model="settings.phone_header.value"
                                                   placeholder="Se muestra en la zona superior"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Teléfono Pie de Página: </label>
                                            <!--- phone_footer --->
                                            <input class="form-control" name="phone_footer" type="tel"
                                                   v-if="settings.phone_footer"
                                                   v-model="settings.phone_footer.value"
                                                   placeholder="Se muestra en la zona inferior"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Whatsapp: </label><small
                                            class="text-danger"> Incluir código de país. Ej. México 52</small>
                                            <!--- whatsapp --->
                                            <input class="form-control" name="whatsapp" type="tel"
                                                   v-if="settings.whatsapp"
                                                   v-model="settings.whatsapp.value"
                                                   placeholder="Botón Flotante de Whatsapp"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Correo Electrónico: </label>
                                            <!--- email --->
                                            <input class="form-control" name="email" type="email" v-if="settings.email"
                                                   v-model="settings.email.value"
                                                   placeholder="Al que los clientes se puedan comunicar"/>
                                        </div>
                                    </div>
                                    <div class="modal-footer my-4"><!--- Línea División-->
                                        <button class="btn btn-success ml-2" type="button"
                                                v-if="$hasPermission('contact_store.update')"
                                                @click="updateSettings('form2')"><i
                                            class="fa fa-tag text-white mr-2"></i> Actualizar
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div class="card-body">
                                <div class="card-title mb-3"><i class="fa fa-google"></i> SEO para motores de búsqueda
                                </div>
                                <form id="form3">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Título de la Página:</label>
                                            <!--- meta_title_home --->
                                            <input class="form-control" name="meta_title_home" type="text"
                                                   v-if="settings.meta_title_home"
                                                   v-model="settings.meta_title_home.value"
                                                   placeholder="El título que aparece en los Motores"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Palabras Clave de la Tienda:</label>
                                            <!--- meta_keywords_home --->
                                            <input class="form-control" name="meta_keywords_home" type="text"
                                                   v-if="settings.meta_keywords_home"
                                                   v-model="settings.meta_keywords_home.value"
                                                   placeholder="Las palabras que escribe el usuario para encontrar tu página, separados por comas"/>
                                        </div>
                                        <div class="form-group col-md-12 my-2">
                                            <label class="ul-form__label" for="">Descripción de la Tienda:</label>
                                            <!--- meta_description_home --->
                                            <input class="form-control" name="meta_description_home" type="text"
                                                   v-if="settings.meta_description_home"
                                                   v-model="settings.meta_description_home.value"
                                                   placeholder="Hasta 250 carácteres"/>
                                        </div>
                                    </div>
                                    <div class="modal-footer my-4"><!--- Línea División-->
                                        <button class="btn btn-success ml-2" type="button"
                                                v-if="$hasPermission('seo.update')"
                                                @click="updateSettings('form3')">
                                            <i class="fa fa-tag text-white mr-2"></i> Actualizar
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div class="card-body">
                                <div class="card-title mb-3"><i class="fa fa-heart"></i> Redes sociales <small
                                    class="text-danger">(Opcional)</small></div>
                                <form id="form4">
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">Instagram:</label>
                                            <!--- instagram_url --->
                                            <input class="form-control" name="instagram_url" type="text"
                                                   v-if="settings.instagram_url"
                                                   v-model="settings.instagram_url.value"
                                                   placeholder="URL Completa de Instagram, Ej. https://instagram.com/toucheim"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Facebook:</label>
                                            <!--- facebook_url --->
                                            <input class="form-control" name="facebook_url" type="text"
                                                   v-if="settings.facebook_url"
                                                   v-model="settings.facebook_url.value"
                                                   placeholder="URL Completa de Instagram, Ej. https://www.facebook.com/toucheim"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">TikTok:</label><!--- tiktok_url --->
                                            <input class="form-control" name="tiktok_url" type="text"
                                                   v-if="settings.tiktok_url"
                                                   v-model="settings.tiktok_url.value"
                                                   placeholder="URL Completa de Instagram, Ej. https://www.tiktok.com/@touchesshop"/>
                                        </div>
                                    </div>
                                    <div class="modal-footer my-4"><!--- Línea División-->
                                        <button class="btn btn-success ml-2" type="button"
                                                v-if="$hasPermission('social_media.update')"
                                                @click="updateSettings('form4')"><i
                                            class="fa fa-tag text-white mr-2"></i> Actualizar
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div class="card-body">
                                <div class="card-title mb-3"><i class="fa fa-shoe-prints"></i> Pie de Página <small
                                    class="text-danger">(Opcional)</small></div>
                                <form id="form5">
                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label class="ul-form__label" for="">Título Pie de Página:</label>
                                            <!--- footer_title --->
                                            <input class="form-control" name="footer_title" type="text"
                                                   placeholder="Título" v-if="settings.footer_title"
                                                   v-model="settings.footer_title.value"/>
                                        </div>
                                        <div class="form-group col-md-9">
                                            <label class="ul-form__label" for="">Descripción Pie de Página:</label>
                                            <!--- footer_description --->
                                            <input class="form-control" name="footer_description" type="text"
                                                   v-if="settings.footer_description"
                                                   v-model="settings.footer_description.value"
                                                   placeholder="Llamada a la acción"/>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="ul-form__label" for="">Título Suscriptores:</label>
                                            <!--- subscription_title --->
                                            <input class="form-control" name="subscription_title" type="text"
                                                   placeholder="Título" v-if="settings.subscription_title"
                                                   v-model="settings.subscription_title.value"/>
                                        </div>
                                        <div class="form-group col-md-9">
                                            <label class="ul-form__label" for="">Llamada a Suscribir:</label>
                                            <!--- subscription_description --->
                                            <input class="form-control" name="subscription_description" type="text"
                                                   v-if="settings.subscription_description"
                                                   v-model="settings.subscription_description.value"
                                                   placeholder="Llamada a la acción"/>
                                        </div>
                                    </div>
                                    <div class="modal-footer my-4"><!--- Línea División-->
                                        <button class="btn btn-success ml-2" type="button"
                                                v-if="$hasPermission('footer.update')"
                                                @click="updateSettings('form5')"><i
                                            class="fa fa-tag text-white mr-2"></i> Actualizar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

            <section>

                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="card-title mb-3"><i class="fa fa-cog"></i> Configuración de la Tienda</div>
                            <form id="form6">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Sitio Web:</label><small
                                        class="text-danger"> Si es distinto a tu dominio actual, por favor contacta
                                        a los desarrolladores</small>
                                        <input class="form-control" name="site_url" type="text"
                                               v-if="settings.site_url && settings.site_url.value"
                                               v-model="settings.site_url.value"
                                               disabled/>
                                        <!--- site_url --->
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Paleta de Colores de tu Tienda:</label>
                                        <!--- css_style --->
                                        <select class="form-control" name="css_style"
                                                v-if="settings.css_style && settings.css_style.value"
                                                v-model="settings.css_style.value">
                                            <option>Seleccionar</option>
                                            <option :value="'black'">Negro</option>
                                            <option :value="'blue'">Azul</option>
                                            <option :value="'yellow'">Amarillo</option>
                                            <option :value="'green'">Verde</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Principal Especificación para Filtrar
                                            Productos en tu tienda:</label>
                                        <!--- search_specification_id --->
                                        <select class="form-control" name="search_specification_id"
                                                v-if="settings.search_specification_id && settings.search_specification_id.value"
                                                v-model="settings.search_specification_id.value">
                                            <option>Seleccionar</option> <!---name de la tabla specifications-->
                                            <option v-for="spec in specifications" :key="spec.id" :value="spec.id">
                                                {{ spec.name }}
                                            </option>
                                            <!-- Se guarda el ID-->
                                        </select>
                                    </div>

                                </div>
                                <div class="modal-footer my-4"><!--- Línea División-->
                                    <button class="btn btn-success ml-2" v-if="$hasPermission('store_config.update')"
                                            type="button" @click="updateSettings('form6')">
                                        <i class="fa fa-tag text-white mr-2"></i> Actualizar
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div class="card-body">
                            <div class="card-title mb-3"><i class="fa fa-money"></i> Pagos <small
                                class="text-danger">Información Importante</small></div>
                            <form id="form7">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">
                                            ¿Quieres recibir pagos a través de Transferencia Interbancaria?:
                                        </label>
                                        <!--- enable_wire_transfer --->
                                        <select class="form-control" style="background: yellow;"
                                                v-if="settings.enable_wire_transfer"
                                                v-model="settings.enable_wire_transfer.value"
                                                name="enable_wire_transfer">
                                            <option :value="1">Sí</option> <!-- Activa Enable_wire_transfer-->
                                            <option :value="0">No</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Banco:</label><!--- payment_bank --->
                                        <select class="form-control" name="payment_bank"
                                                v-if="settings.payment_bank && settings.payment_bank.value"
                                                v-model="settings.payment_bank.value">
                                            <option :value="0">Seleccionar</option>
                                            <option :value="'ABC Capital'">ABC Capital</option>
                                            <option :value="'Actinver'">Actinver</option>
                                            <option :value="'Afirme'">Afirme</option>
                                            <option :value="'American Express'">American Express</option>
                                            <option :value="'Autofin'">Autofin</option>
                                            <option :value="'Bamsa'">Bamsa</option>
                                            <option :value="'Banco Azteca'">Banco Azteca</option>
                                            <option :value="'Banco del Bajio'">Banco del Bajio</option>
                                            <option :value="'Banco del Bienestar'">Banco del Bienestar</option>
                                            <option :value="'Banco Multiva'">Banco Multiva</option>
                                            <option :value="'Banjercito'">Banjercito</option>
                                            <option :value="'Banorte'">Banorte</option>
                                            <option :value="'Banpay'">Banpay</option>
                                            <option :value="'Banregio'">Banregio</option>
                                            <option :value="'Bansi'">Bansi</option>
                                            <option :value="'Barclays'">Barclays</option>
                                            <option :value="'BBVA'">BBVA</option>
                                            <option :value="'BanCoppel'">BanCoppel</option>
                                            <option :value="'Bancrea'">Bancrea</option>
                                            <option :value="'Bankaool'">Bankaool</option>
                                            <option :value="'Bansefi'">Bansefi</option>
                                            <option :value="'Bank of America'">Bank of America</option>
                                            <option :value="'Bank of China'">Bank of China</option>
                                            <option :value="'Bx+'">Bx+</option>
                                            <option :value="'CI Banco'">CI Banco</option>
                                            <option :value="'Citibanamex'">Citibanamex</option>
                                            <option :value="'Compártamos Banco'">Compártamos Banco</option>
                                            <option :value="'Coppel'">Coppel</option>
                                            <option :value="'Credit Suisse First Boston'">Credit Suisse First Boston
                                            </option>
                                            <option :value="'Deutsche'">Deutsche</option>
                                            <option :value="'Efectivo'">Efectivo</option>
                                            <option :value="'En Especie'">En Especie</option>
                                            <option :value="'Famsa'">Famsa</option>
                                            <option :value="'HSBC'">HSBC</option>
                                            <option :value="'Inbursa'">Inbursa</option>
                                            <option :value="'Ing'">Ing</option>
                                            <option :value="'Interacciones'">Interacciones</option>
                                            <option :value="'Intercam'">Intercam</option>
                                            <option :value="'Invex'">Invex</option>
                                            <option :value="'IXE'">IXE</option>
                                            <option :value="'JP Morgan'">JP Morgan</option>
                                            <option :value="'Otro'">Otro</option>
                                            <option :value="'Royal Bank of Canada (RBC)'">Royal Bank of Canada (RBC)
                                            </option>
                                            <option :value="'Saldo a Favor'">Saldo a Favor</option>
                                            <option :value="'Santander'">Santander</option>
                                            <option :value="'Scotiabank'">Scotiabank</option>
                                            <option :value="'Solano'">Solano</option>
                                            <option :value="'Tokyo'">Tokyo</option>
                                            <option :value="'UBS Bank'">UBS Bank</option>
                                            <option :value="'Ve por más'">Ve por más</option>
                                            <option :value="'Walmart'">Walmart</option>

                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Nombre del Beneficiario de la
                                            Cuenta:</label><!--- payment_beneficiary --->
                                        <input class="form-control" name="payment_beneficiary" type="text"
                                               placeholder="Razón Social"
                                               v-if="settings.payment_beneficiary && settings.payment_beneficiary.value"
                                               v-model="settings.payment_beneficiary.value"/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Número de Cuenta:</label>
                                        <!--- payment_account --->
                                        <input class="form-control" name="payment_account" type="text"
                                               placeholder="Número"
                                               v-if="settings.payment_account && settings.payment_account.value"
                                               v-model="settings.payment_account.value"/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">CLABE Interbancaria:</label>
                                        <!--- payment_clabe --->
                                        <input class="form-control" name="payment_clabe" type="text"
                                               placeholder="18 digitos"
                                               v-if="settings.payment_clabe && settings.payment_clabe.value"
                                               v-model="settings.payment_clabe.value"/>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">
                                            ¿Quieres recibir pagos con tarjeta?:
                                        </label>
                                        <!--- enable_clip_payment  // enable_openpay--->
                                        <select class="form-control" style="background: yellow;"
                                                name="payment_option" v-model="selectedPaymentOption">
                                            <option value="0">No</option>
                                            <option value="openpay">Sí, con OpenPay</option>
                                            <option value="clip">Sí, con Clip</option>
                                        </select>
                                    </div>
                                    <div class="form-group  col-md-12">
                                        <label class="ul-form__label" for="">
                                            Imagen Tarjetas Recibidas:
                                        </label>
                                        <small class="text-danger">
                                            Tamaño de la imagen 246 px X 24 px
                                        </small>
                                        <div class="input-group mb-12">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupFileAddon01">
                                                    Cargar
                                                </span>
                                            </div>
                                            <div class="custom-file">
                                                <input class="custom-file-input" name="payment_image"
                                                       id="inputGroupFile01" type="file"
                                                       aria-describedby="inputGroupFileAddon01"/>
                                                <!--- payment_image --->
                                                <label class="custom-file-label" for="inputGroupFile01">
                                                    Elegir Archivo *.PNG
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <img v-if="settings.payment_image" class=""
                                             :src="'/images/'+settings.payment_image.value"/>
                                        <!--- payment_image --->
                                    </div>


                                </div>
                                <div class="modal-footer my-4"><!--- Línea División-->
                                    <button class="btn btn-success ml-2"
                                            v-if="$hasPermission('payment_info.update')"
                                            type="button" @click="updateSettings('form7')">
                                        <i
                                            class="fa fa-tag text-white mr-2"></i> Actualizar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>

            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

            <section>

                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="card-title mb-3"><i class="fa fa-laptop-code"></i> Desarrolladores</div>
                            <p>Por favor, sólo ayúdanos a corroborar que estos datos coinciden con las credenciales
                                de tus páginas de Administración de los Servicios Contratados con Terceros</p>
                            <form id="form8">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Algolia ID (Buscador): <a
                                            href="https://dashboard.algolia.com/users/sign_in"
                                            target="_blank">Visitar</a></label>
                                        <input class="form-control" name="" v-if="apiKeys.algolia_appid"
                                               v-model="apiKeys.algolia_appid.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Algolia API Key:</label>
                                        <input class="form-control" name="" v-if="apiKeys.algolia_apikey"
                                               v-model="apiKeys.algolia_apikey.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Mailjet API Key (Correo Electrónico):
                                            <a href="https://app.mailjet.com/" target="_blank">Visitar</a></label>
                                        <input class="form-control" name="" v-if="apiKeys.mailjet_apikey"
                                               v-model="apiKeys.mailjet_apikey.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Mailjet Secret Key (No la compartas con
                                            nadie):</label>
                                        <input class="form-control" name="" v-if="apiKeys.mailjet_secret"
                                               v-model="apiKeys.mailjet_secret.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Clip Token (Pagos con Tarjeta): <a
                                            href="https://dashboard.clip.mx/" target="_blank">Visitar</a></label>
                                        <input class="form-control" name="" v-if="apiKeys.clip_token"
                                               v-model="apiKeys.clip_token.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="ul-form__label" for="">OpenPay Commercial ID (Pagos Tarjeta):
                                            <a href="https://dashboard.openpay.mx/login" target="_blank">Visitar</a>
                                            o <a href="https://portalopenpay.openpay.mx/login"
                                                 target="_blank">Ingresar</a></label>
                                        <input class="form-control" name="" v-if="apiKeys.openpay_id_comercial"
                                               v-model="apiKeys.openpay_id_comercial.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="ul-form__label" for="">OpenPay Public Key:</label>
                                        <input class="form-control" name="" v-if="apiKeys.openpay_public_key"
                                               v-model="apiKeys.openpay_public_key.value" type="text" disabled/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="ul-form__label" for="">OpenPay Secret Key (No la compartas con
                                            nadie):</label>
                                        <input class="form-control" name="" v-if="apiKeys.openpay_private_key"
                                               v-model="apiKeys.openpay_private_key.value" type="text" disabled/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>


            <!-- end of main-content -->
        </div>
    </div>
</template>

<style scoped>
#editor {
    height: 250px; /* Ajusta este valor a la altura que desees */
}

.switch {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 25px;
    border-radius: 20px;
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}

.switch::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 22px;
    height: 22px;
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked + .switch {
    background: #72da67;
}

input:checked + .switch::before {
    left: 47px;
    background: #fff;
}

input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}
</style>
