<script setup>
import {ref, onMounted, watchEffect, computed} from 'vue';
import {gralHook} from "../../../Hooks/gralHook";
import {settingFaqService} from "../../../services/Faqs.service";

const {getAlert, valFormularios} = gralHook();
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import sweetAlert from "sweetalert2";

let quill = null;

const vModel = ref({
    question: '',
    answer: '',
    order: 0,
    type: 0,
});

const modificar = ref(false);

const onSave = async () => {
    let resp;
    resp = valFormularios('formFaqs');
    if (resp) {
        if (quill.root.innerHTML) {
            vModel.value.answer = quill.root.innerHTML;
            if (modificar.value) {
                resp = await settingFaqService.updateOne(vModel.value.id, vModel.value);
            } else {
                resp = await settingFaqService.saveOne(vModel.value);
            }
            modificar.value = false;
            getAlert(resp.process);
        } else {
            getAlert('empty_ritch_text');
        }
    }
};

const onUpdate = async (faq) => {
    vModel.value = faq;
    quill.root.innerHTML = faq.answer;
    modificar.value = true;
};

const onDelete = async (id) => {


    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await settingFaqService.deleteOne(id);
            getAlert(resp.process);
            await onIndex();
        }
    })

};

const settingFaqs = ref([]);

onMounted(async () => {
    quill = new Quill("#answer", {
        theme: "snow",
    });
    settingFaqs.value = await settingFaqService.getFaqs();
});

</script>


<template>
    <div>
        <!-- begin::modal Faqs-->
        <div id="faq" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Pregunta Frecuente</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="formFaqs" class="formFaqs">
                            <div class="card-body">
                                <div class="form-row">
                                    <label class="ul-form__label" for="">seleccione el tipo de pregunta</label>
                                    <select v-model="vModel.type" name="type" class="form-control required">
                                        <option :value="1">Envios</option>
                                        <option :value="2">Devoluciones</option>
                                        <option :value="3">Otros</option>
                                    </select>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Pregunta:</label>
                                        <input v-model="vModel.question" name="question" class="form-control required"
                                               id="" type="text"
                                               placeholder="Breve texto"/>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Respuesta:</label>
                                        <div id="answer" class="mb-4"></div>
                                        <!--- Aquí deberían poder hacer el salto de línea un Rich-Text-->
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave()">
                            Añadir
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal EDIT Comments-->
        <!-- ============ Body content start ============= -->
        <section class="faqs-list">
            <div class="row">
                <div class="col-md-12 mb-4">
                    <div class="text-left">
                        <div class="card-header text-right bg-transparent">
                            <button class="btn btn-success btn-md m-1"
                                    type="button"
                                    data-toggle="modal"
                                    v-if="$hasPermission('faqs.create')"
                                    data-target="#faq">
                                <i class="fa fa-clone text-white mr-2"></i> Agregar Pregunta Frecuente
                            </button>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive table-striped">
                                <table class="display table" id="ul-contact-list" style="width:100%">
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Pregunta</th>
                                        <th>Respuesta</th>
                                        <th>Tipo</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <!--- Inicia ITEM-->
                                    <template v-for="faq in settingFaqs" :key="faq.id">
                                        <tr>
                                            <td style="padding-top: 20px;">{{ faq.id }}</td>
                                            <td style="padding-top: 20px;">{{ faq.question }}</td>
                                            <td style="padding-top: 20px;" v-html="faq.answer "></td>
                                            <td>
                                                <div v-if="faq.type=='1'">
                                                    Envios
                                                </div>
                                                <div v-if="faq.type=='2'">
                                                    Devoluciones
                                                </div>
                                                <div v-if="faq.type=='3'">
                                                    Otros
                                                </div>
                                            </td>
                                            <td>
                                                <!-- BOTÓN MODAL Revisar Orden-->

                                                <button class="btn btn-warning btn-md m-1" type="button"
                                                        title="Ver Orden" data-toggle="modal"
                                                        v-if="$hasPermission('faqs.edit')"
                                                        data-target="#faq" @click="onUpdate(faq)">
                                                    <i class="fa fa-edit text-white"></i>
                                                </button>

                                                <!-- BOTÓN BORRAR-->
                                                <button class="btn btn-danger btn-md m-1" type="button"
                                                        v-if="$hasPermission('faqs.delete')"
                                                        title="Anular Pedido" @click="onDelete(faq.id)">
                                                    <i class="fa fa-trash text-white"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                    <!--- Termina Item-->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
#answer {
    height: 250px
}
</style>
