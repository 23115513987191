<script setup>
import {ref, onMounted} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {Dropzone} from "dropzone";
import JsCookie from "js-cookie";
import {galleryProductsService} from "../../../services/GalleryProducts.service";
import sweetAlert from "sweetalert2";

//DATA
const {getAlert, valFormularios, goBack} = gralHook();

const dropRef = ref(null);

const flagDropzone = ref(false);

const listGallery = ref([]);

const props = defineProps({
    product_id: {
        type: Number,
        required: true,
    }
});

const selectedImage = ref({
    id: 0,
    image: ''
});

const initDropZone = () => {
    if (flagDropzone.value === false && dropRef.value !== null) {
        flagDropzone.value = true;
        new Dropzone(dropRef.value, {
            previewTemplate: customPreview,
            url: '/api/gallery-product/' + props.product_id,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + JsCookie.get('F1') + '.' + JsCookie.get('R3') + '.' + JsCookie.get('B1rd')
            },
            previewsContainer: dropRef.value.parentElement.querySelector('.preview-container'),
            init: function () {
                this.on("complete", async function (file) {
                    if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                        getAlert('images_upload');
                        await onIndex();
                    }
                });
            }
        })
        if (dropRef.value.querySelector('.dz-default')) {
            dropRef.value.querySelector('.dz-default').innerHTML = `
            <div style="display: flex; justify-content: center;">
              <svg width="10em" height="10em" viewBox="0 0 16 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="pointer-events: none;">
                <path fill-rule="evenodd" d="m 8.0274054,0.49415269 a 5.53,5.53 0 0 0 -3.594,1.34200001 c -0.766,0.66 -1.321,1.52 -1.464,2.383 -1.676,0.37 -2.94199993,1.83 -2.94199993,3.593 0,2.048 1.70799993,3.6820003 3.78099993,3.6820003 h 8.9059996 c 1.815,0 3.313,-1.43 3.313,-3.2270003 0,-1.636 -1.242,-2.969 -2.834,-3.194 -0.243,-2.58 -2.476,-4.57900001 -5.1659996,-4.57900001 z m 2.3539996,5.14600001 -1.9999996,-2 a 0.5,0.5 0 0 0 -0.708,0 l -2,2 a 0.5006316,0.5006316 0 1 0 0.708,0.708 l 1.146,-1.147 v 3.793 a 0.5,0.5 0 0 0 1,0 v -3.793 l 1.146,1.147 a 0.5006316,0.5006316 0 0 0 0.7079996,-0.708 z"/>
              </svg>
            </div>
            <p style="text-align: center; margin: 0;"><strong>Drag and drop files to upload</strong></p>
            <p style="text-align: center; margin-top: 0;"><small style="color: #999;">Your files will be added automatically</small></p>
            <button class="dz-button" type="button" style="display:block; border: 2px solid currentColor; border-radius: 5px; font-weight: 700; margin:auto; padding: 10px;">or select files</button>
          `
        }
    }
}

const customPreview = `
      <div class="dz-preview dz-processing dz-image-preview dz-complete">
        <div class="dz-image">
          <img data-dz-thumbnail alt="">
        </div>
        <div class="dz-details">
          <div class="dz-size"><span data-dz-size></span></div>
            <div class="dz-filename"><span data-dz-name></span></div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
          <div class="dz-success-mark">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z" />
            </svg>
          </div>
          <div class="dz-error-mark">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.2929 20.2929L19.2071 13.2071C18.8166 12.8166 18.1834 12.8166 17.7929 13.2071L13.2071 17.7929C12.8166 18.1834 12.8166 18.8166 13.2071 19.2071L20.2929 26.2929C20.6834 26.6834 20.6834 27.3166 20.2929 27.7071L13.2071 34.7929C12.8166 35.1834 12.8166 35.8166 13.2071 36.2071L17.7929 40.7929C18.1834 41.1834 18.8166 41.1834 19.2071 40.7929L26.2929 33.7071C26.6834 33.3166 27.3166 33.3166 27.7071 33.7071L34.7929 40.7929C35.1834 41.1834 35.8166 41.1834 36.2071 40.7929L40.7929 36.2071C41.1834 35.8166 41.1834 35.1834 40.7929 34.7929L33.7071 27.7071C33.3166 27.3166 33.3166 26.6834 33.7071 26.2929L40.7929 19.2071C41.1834 18.8166 41.1834 18.1834 40.7929 17.7929L36.2071 13.2071C35.8166 12.8166 35.1834 12.8166 34.7929 13.2071L27.7071 20.2929C27.3166 20.6834 26.6834 20.6834 26.2929 20.2929Z" />
          </svg>
        </div>
      </div>
    `

const onIndex = async () => {
    listGallery.value = await galleryProductsService.getTheGalleryProducts(props.product_id);
    //console.log('listGallery-',listGallery.value);
}

const onDelete = async (idFolder, idImage) => {

    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await galleryProductsService.deleteImage(idFolder, idImage);
            getAlert(resp.process);
            await onIndex();
        }
    })

}

const showImage = (imageName, idImage) => {
    selectedImage.value.image = imageName;
    selectedImage.value.id = idImage;
}

const setMainImage = async (productID, idImage) => {
    let resp = await galleryProductsService.setMainImage(productID, idImage);
    getAlert(resp.process);
    await onIndex();
}

onMounted(async () => {
    await onIndex();
    initDropZone();
});
</script>

<template>
    <!--  Modal -->
    <div class="modal fade" id="show-image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Imagen ID: <strong
                        class="text-danger">{{ selectedImage.id }}</strong></h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <img v-if="selectedImage.image" :src="'/images/products/'+props.product_id+'/'+selectedImage.image"
                         alt=""/>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="card mb-4">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-md-12 mb-6">
                        <div class="card-title mb-3"><i class="fa fa-image"></i> Fotografías del Producto</div>
                        <div class="form-group col-md-12">
                            <label class="ul-form__label" for="">Arrastre las fotos del Producto. Max.
                                2Mb</label>
                            <h5 class="text-danger">Cargar demasiadas imagenes a este módulo podría generar
                                excesivos <strong>costos de Hospedaje y tráfico Web</strong></h5>
                        </div>
                        <div ref="dropRef" class="dropzone custom-dropzone"></div>
                        <div class="dropzone preview-container"></div>
                    </div>
                </div>
            </div>

            <div class="card-body">

                <div class="table-responsive table-striped">
                    <table class="display table" id="ul-contact-list" style="width:100%">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Thumbnail</th>
                            <th>Acciones</th>
                            <th>Imagen Principal</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="gal in listGallery" :key="gal.id">
                            <tr>
                                <td style="padding-top: 40px;">{{ gal.id }}</td>
                                <td>
                                    <a href="">
                                        <div class="ul-widget-app__profile-pic">
                                            <img class="avatar-lg"
                                                 :src="'/images/products/'+props.product_id+'/'+gal.image"
                                                 alt=""/>
                                        </div>
                                    </a>
                                </td>
                                <td style="padding-top: 28px;">

                                    <button class="btn btn-primary btn-md m-1" type="button"
                                            data-toggle="modal"
                                            data-target="#show-image"
                                            title="Ver imagen"
                                            ref="btnShowImage"
                                            @click="showImage(gal.image, gal.id)">
                                        <i class="fa fa-image text-white"></i>
                                    </button>

                                    <button class="btn btn-danger btn-md m-1" type="button"
                                            @click="onDelete(props.product_id,gal.id)"
                                            v-if="$hasPermission('gallery_product.delete')"
                                            title="Eliminar Producto">
                                        <i class="fa fa-trash text-white"></i>
                                    </button>
                                </td>
                                <td style="padding-top: 45px;">
                                    <label class="switch pr-5 switch-primary mr-3"
                                           v-if="$hasPermission('gallery_product.change_main')">
                                        <span class="text-primary">Establecer como Imagen Principal</span>
                                        <input type="checkbox"
                                               :checked="gal.type === 1"
                                               @change="setMainImage(props.product_id,gal.id)"/>
                                        <span class="slider"></span>
                                    </label>
                                </td>
                            </tr>
                        </template>

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('https://unpkg.com/dropzone@6.0.0-beta.1/dist/dropzone.css');

.custom-dropzone {
    border-style: dashed;
    border-width: 3px;
    padding: 20px;
}

.preview-container {
    border: none !important;
}

.dropzone .dz-message {
    margin: 0;
}
</style>

