<script setup>
import {ref, onMounted, nextTick, watch} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {specificationsHeadersService} from "../../../services/SpecificationsHeaders.service.js";
import sweetAlert from "sweetalert2";

const {getAlert, valFormularios, goBack} = gralHook();
const vModel = ref({
    id: '',
    name: ''
});
const modificar = ref(false);
const order = ref([]);
const headers_spec = ref([]);
const fields = ref([{name: ''}]);
const draggedItem = ref(null);
const items = ref([]);
const dragAndDropEnabled = ref(false);
const trashList = ref(false);

//METHODS PERSONALIZES
const addFields = () => {
    const lastField = fields.value[fields.value.length - 1];
    if (lastField.name !== '') {
        fields.value.push({name: ''});
    } else {
        getAlert('empty_header');
    }
};

const onDelete = async (id) => {
    //ghay que checar que no tenga hijos ppara poder eliminarlo, si tiene hijos no se puede eliminar
    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp;
            resp = await specificationsHeadersService.deleteOne(id);
            getAlert(resp.process);
        }
    })

};

const onRestore = async (id) => {
    let resp;
    resp = await specificationsHeadersService.restoreTrash(id);
    getAlert(resp.process);
};

const onSave = async () => {
    let dataToSend = {
        fields: fields.value,
    };
    let resp;
    resp = valFormularios('crudFormHeader');

    if (resp) {
        //console.log('fields', fields.value);
        if (modificar.value) {
            resp = await specificationsHeadersService.updateOne(vModel.value.id, dataToSend);
        } else {
            resp = await specificationsHeadersService.saveOne(dataToSend);
        }
        getAlert(resp.process, resp.error);
    }

};

const removeField = (index) => {
    fields.value.splice(index, 1);
};

const updateField = (index, data) => {
    fields.value[0].name = data.name;
    vModel.value.id = data.id;
    modificar.value = true;
};

const onIndex = async () => {
    headers_spec.value = await specificationsHeadersService.getMany();
};

//METODOS DE DRAG AND DROP
const enableDragAndDrop = () => {
    dragAndDropEnabled.value = !dragAndDropEnabled.value;
    const items = document.querySelectorAll('[draggable]');
    items.forEach(item => {
        item.setAttribute('draggable', true);
    });
};

const handleDragStart = (index) => {
    draggedItem.value = index;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDrop = (index) => {
    const droppedItem = headers_spec.value[draggedItem.value];
    headers_spec.value = headers_spec.value.filter((item, i) => i !== draggedItem.value);
    headers_spec.value.splice(index, 0, droppedItem);
    draggedItem.value = null;
    //console.log('fieldsDrop', fields.value);
    // Actualizar el orden de los elementos en el array fields
    headers_spec.value = headers_spec.value.map((field, index) => ({...field, order: index}));
    //console.log('fieldsDrop', headers_spec.value);
};

const handleDragEnd = async () => {
    await updateOrder();
    draggedItem.value = null;
};

const updateOrder = async () => {
    //console.log('headers_spec', headers_spec.value);
    const data = {
        specificationHeaders: headers_spec.value
    };
    let resp = await specificationsHeadersService.updateOrder(data);
    getAlert(resp.process);
};

//METODOS DE VIEW
watch(fields, () => {
    nextTick();
}, {deep: true});

onMounted(async () => {
    await onIndex();
});

</script>

<template>
    <div class="col-md-6 mb-4">
        <!-- begin::modal ADD TYPESSPECIFICATIONS-->
        <div id="add-type" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Añadir <strong class="text-danger">TIPOS de
                            Especificaciones</strong> Masivamente</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="crudFormHeader" class="crudFormHeader">
                            <div class="row">
                                <div class="form-group col-md-10">
                                    <label class="ul-form__label" for="">Nombre:</label>
                                </div>
                                <div class="form-group col-md-1">
                                    <button class="btn btn-success ml-2" type="button" v-if="!modificar"
                                            @click="addFields">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <template v-for="(field, index) in fields" :key="index">
                                <!--- ITEM Start-->
                                <div class="row">
                                    <div class="form-group col-md-10">
                                        <input class="form-control" v-model="field.name" type="text"
                                               placeholder="Nombre del Tipo de Especificación"/><small
                                        class="ul-form__text form-text">
                                    </small>
                                    </div>
                                    <div class="form-group col-md-1">
                                        <button class="btn btn-danger ml-2" type="button" @click="removeField(index)"
                                                v-if="index > 0"><i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- Item End-->
                            </template>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave">Añadir</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal ADD TYPES SPECIFICATIONS-->
        <div class="card text-left">
            <div class="card-header text-right bg-transparent">
                <button class="btn btn-warning btn-md m-1" type="button" @click="enableDragAndDrop()"><i
                    class="fa fa-sort-amount-down mr-2"></i> Reordenar
                </button>
                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                        data-target="#add-type">
                    <i class="fa fa-plus text-white mr-2"></i> Agregar Tipo
                </button>
            </div>
            <div class="card-body">
                <div class="table-responsive table-striped">
                    <table class="display table" id="ul-contact-list" style="width:100%">
                        <thead>
                        <tr>
                            <th v-if="dragAndDropEnabled">Arrastrar</th>
                            <th>ID</th>
                            <th>Tipo de Especificación</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(header,index) in headers_spec" :key="header.id">
                            <!--- Inicia ITEM-->
                            <tr class="drag"
                                :data-index="index"
                                :draggable="false"
                                @dragstart="handleDragStart(index)"
                                @dragover.prevent="handleDragOver"
                                @drop="handleDrop(index)"
                                @dragend="handleDragEnd"
                                :class="(dragAndDropEnabled)?'pointerCursor':''">
                                <td class="form-group col-md-2" v-if="dragAndDropEnabled">
                                    <i class="fa fa-sort-amount-down"></i>
                                </td>
                                <td style="vertical-align: middle !important;">{{ header.id }}</td>
                                <td style="vertical-align: middle !important;">{{ header.name }}</td>
                                <td style="vertical-align: middle !important;">
                                    <!-- BOTÓN MODAL Editar -->
                                    <button v-if="!trashList" class="btn btn-warning btn-md m-1"
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#add-type"
                                            title="Editar Categoría" @click="updateField(index, header)">
                                        <i class="fa fa-edit text-white"></i>
                                    </button>
                                    <!-- BOTÓN MODAL Eliminar  Soft Delete-->
                                    <button v-if="!trashList" class="btn btn-danger btn-md m-1"
                                            type="button"
                                            data-toggle="modal" data-target="#delete-category"
                                            title="Eliminar Categoría" @click="onDelete(header.id)"><i
                                        class="fa fa-trash text-white"></i></button>
                                    <!-- Solo aparece en los Soft Delete BOTÓN MODAL Reactivar  Quitar Soft Delete-->
                                    <button v-if="trashList" class="btn btn-success btn-md m-1"
                                            type="button"
                                            data-toggle="modal" data-target="#activate-category"
                                            title="Reactivar Categoría" @click="onRestore(header.id)">
                                        <i class="fa fa-check text-white"></i>
                                    </button>

                                </td>
                            </tr>
                            <!--- Termina Item-->
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pointerCursor {
    cursor: pointer;
}
</style>
