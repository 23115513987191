<script setup>
import {onMounted, ref, watch} from 'vue';
import {productsService} from "../../../services/Products.service";
import {gralHook} from "../../../Hooks/gralHook.js";
import {brandsService} from "../../../services/Brands.service";
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import sweetAlert from "sweetalert2";
import {settingsService} from "../../../services/Settings.service";


//DATA
const {getAlert, valFormularios, goBack, getFormData, generateFriendlyUrl} = gralHook();

const props = defineProps({
    productId: {
        type: [String, Number],
        default: null,
    },
});

let quill = null;

const pID = ref(props.productId);

const vModel = ref({
    sku: '',
    name: '',
    type: 1,
    brand_id: 0,
    price_public: '',
    stock: '',
    friendly_url: '',
    description_short: '',
    description_long: '',
    pdf_product: '',
});

let site_url = ref('');

const modificar = ref(false);

const brands = ref([]);

const relArray = ref([]);

//METHODS
const onDelete = async (id) => {
    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await productsService.deleteOne(id);
            getAlert(resp.process);
            await onIndex();
        }
    })
};

const onSave = async () => {
    let resp;
    resp = valFormularios('crudForm');
    const formData = getFormData('crudForm');
    formData.append('description_long', quill.root.innerHTML);
    if (resp) {
        if (modificar.value) {
            resp = await productsService.updateOne(vModel.value.id, formData, {isForm: true});
        } else {
            resp = await productsService.saveOne(formData, {isForm: true});
        }
        getAlert(resp.process, resp.error);
    }
};

const onIndex = async () => {

    let _settings = await settingsService.getMany();

    for (let setting of _settings) {
        if (setting.key === 'site_url') {
            site_url.value = setting.value;
            break;
        }
    }

    quill = new Quill("#editor", {
        theme: "snow",
    });

    const brandResult = await brandsService.getMany();

    brands.value = Array.isArray(brandResult) ? brandResult : [];

    if (props.productId) {
        //console.log('producto a modificar');
        vModel.value = await productsService.getProduct(pID.value);
        //console.log('vModel DL- ', vModel.value.description_long);
        quill.root.innerHTML = vModel.value.description_long;
    }

};

onMounted(async () => {
    await onIndex();
});

watch(() => vModel.value.name, (newVal) => {
    vModel.value.friendly_url = generateFriendlyUrl(newVal);
});

watch(() => props.productId, async (newVal) => {
    pID.value = newVal;
    if (pID.value) {
        modificar.value = true;
    }
});

</script>

<template>
    <div id="add_product_component">
        <!-- ============ Body content start ============= -->
        <div class="main-content">

            <div class="breadcrumb">
                <h1 class="mr-2">Detalle del Producto</h1>
            </div>

            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

            <!-- BOTÓN CERRAR VENTANA --->
            <div class="card-header text-right bg-transparent">
                <button class="btn btn-danger btn-md m-1" type="button" @click="goBack()">
                    <i class="fa fa-angle-left text-white mr-2"></i> Regresar
                </button>
            </div>

            <section>
                <div>
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <div class="card-body">
                                <div class="card-title mb-3"><i class="fa fa-tag"></i> Información General</div>
                                <form id="crudForm" class="crudForm">
                                    <template v-if="modificar">
                                        <input type="hidden" name="currentPdfProduct" v-model="vModel.pdf_product">
                                    </template>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">SKU:</label>
                                            <input name="sku" class="form-control" v-model="vModel.sku" type="text"
                                                   placeholder="SKU Único del Producto"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Nombre del Producto:</label>
                                            <input name="name" class="form-control" v-model="vModel.name" type="text"
                                                   placeholder="Nombre Completo del Producto"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Tipo:</label>
                                            <select name="type" v-model="vModel.type" class="form-control">
                                                <option :value="1">Producto</option>
                                                <option :value="2">Refacción</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Marca:</label>
                                            <select name="brand_id" v-model="vModel.brand_id" class="form-control">
                                                <option :value="0">Seleccionar</option>
                                                <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                                                    {{ brand.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Precio Público:</label>
                                            <input name="price_public" class="form-control"
                                                   v-model="vModel.price_public" type="number" placeholder="$ 0.00"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Existencias:</label>
                                            <input name="stock" class="form-control" v-model="vModel.stock"
                                                   type="number" placeholder="0"/>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">
                                                URL Amigable (No usar carácteres epeciales):</label>
                                        </div>
                                        <div class="input-group col-md-12">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon3">
                                                    {{ site_url }}/producto/
                                                </span>
                                            </div>
                                            <input name="friendly_url" v-model="vModel.friendly_url"
                                                   class="form-control" id="basic-url"
                                                   type="text"
                                                   aria-describedby="basic-addon3"
                                                   placeholder="Puede llevar guiones - SIN carácteres especiales (, . + / & % ´´ ), sólo palabras, sin acentos, "/>
                                        </div>
                                        <div class="form-group col-md-12 my-2">
                                            <label class="ul-form__label" for="">Descripción Corta:</label>
                                            <input name="description_short" v-model="vModel.description_short"
                                                   class="form-control" id=""
                                                   type="text" placeholder="Hasta 250 carácteres"/>
                                        </div>
                                        <div class="form-group  col-md-12">
                                            <label class="ul-form__label" for="">Descripción Completa:</label>
                                            <div id="editor" class="mb-4"></div>
                                        </div>
                                        <div class="form-group  col-md-12">
                                            <span v-if="vModel.pdf_product"><strong>Archivo PDF Actual:</strong> <span
                                                class="text-danger">{{ vModel.pdf_product }}</span></span>
                                            <br>
                                            <label class="ul-form__label" for="">Archivo PDF:</label>
                                            <div class="input-group mb-12">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"
                                                          id="inputGroupFileAddon01">Cargar</span>
                                                </div>
                                                <div class="custom-file">
                                                    <input name="pdf_product" class="custom-file-input"
                                                           id="inputGroupFile01" type="file"
                                                           aria-describedby="inputGroupFileAddon01"/>
                                                    <label class="custom-file-label" for="inputGroupFile01">Elegir
                                                        Archivo *.PDF</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group  col-md-12">
                                            <span v-if="vModel.img_spares">
                                                <strong>Imagen de Refacciones:</strong>
                                                <span class="text-danger">{{ vModel.img_spares }}</span>
                                            </span>
                                            <br>
                                            <label class="ul-form__label" for="">Archivo Refacciones:</label>
                                            <div class="input-group mb-12">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"
                                                          id="inputGroupFileAddon01">Cargar</span>
                                                </div>
                                                <div class="custom-file">
                                                    <input name="img_spares" class="custom-file-input"
                                                           id="inputGroupFile01" type="file"
                                                           aria-describedby="inputGroupFileAddon01"/>
                                                    <label class="custom-file-label" for="inputGroupFile01">
                                                        Elegir Imagen
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer my-4"><!--- Línea División-->
                                        <!--- Este botón aparece al editar, al igual que los forms que van de aquí en adelante-->
                                        <button class="btn btn-success ml-2" type="button" @click="onSave()" v-if="$hasPermission('products.store')">
                                            <i class="fa fa-tag text-white mr-2"></i> Guardar
                                        </button>
                                    </div>
                                </form>
                                <specification_products v-if="modificar" :product_id="pID"></specification_products>
                                <seo_product v-if="modificar" :product_id="pID"></seo_product>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

            <gallery_product v-if="modificar" :product_id="pID"></gallery_product>

            <relation_products v-if="modificar" :product_id="pID"></relation_products>
            <!-- end of main-content -->
        </div>
    </div>
</template>

<style scoped>
#editor {
    height: 250px; /* Ajusta este valor a la altura que desees */
}
</style>
