<script setup>
import {ref, onMounted, nextTick, reactive} from 'vue';
import {gralHook} from "../../../../Hooks/gralHook";
import {homeSliderService} from "../../../../services/HomeSlider.service";
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import sweetAlert from "sweetalert2";

const {getAlert, valFormularios, getFormData} = gralHook();

const sliders = ref([]);

let quill = null;

let modalImg = reactive({
    img: ''
});

const modificar = ref(false);

const vModel = ref({
    id: 0,
    title: '',
    description: '',
    title_button: '',
    url_button: '',
    img_full: '',
    img_mobile: ''
});

const onSave = async () => {
    let resp;
    resp = valFormularios('crudForm');
    if (resp) {
        const formData = getFormData('crudForm');
        formData.append('title', quill.root.innerHTML);
        if (modificar.value) {
            resp = await homeSliderService.updateOne(vModel.value.id, formData, {isForm: true});
        } else {
            resp = await homeSliderService.saveOne(formData, {isForm: true});
        }
        getAlert(resp.process);
    }

}

const onDelete = async (id) => {

    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await homeSliderService.deleteOne(id);
            getAlert(resp.process);
        }
    })
}

const onUpdate = (slide) => {
    try {
        modificar.value = true;
        vModel.value = slide;
        quill.root.innerHTML = slide.title;
    } catch (error) {
        console.error('Error al actualizar el slider:', error);
    }
}

const openModalImg = async (img) => {
    modalImg.img = img;
    await nextTick();
}

const onIndex = async () => {
    sliders.value = await homeSliderService.getMany();
    quill = new Quill("#editor", {
        theme: "snow",
    });
}

onMounted(async () => {
    await onIndex();
})

//START DRAG AND DROP
const draggedItem = ref(null);

const items = ref([]);

const dragAndDropEnabled = ref(false);

const enableDragAndDrop = () => {
    dragAndDropEnabled.value = !dragAndDropEnabled.value;
    const items = document.querySelectorAll('[draggable]');
    items.forEach(item => {
        item.setAttribute('draggable', true);
    });
};

const handleDragStart = (index) => {
    //console.log('handleDragStart', index);
    draggedItem.value = index;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDrop = (index) => {
    const droppedItem = sliders.value[draggedItem.value];
    sliders.value = sliders.value.filter((item, i) => i !== draggedItem.value);
    sliders.value.splice(index, 0, droppedItem);
    draggedItem.value = null;
    sliders.value = sliders.value.map((field, index) => ({...field, order: index}));
};

const handleDragEnd = async () => {
    draggedItem.value = null;
    await updateOrder();
};

const updateOrder = async () => {
    const data = {
        slider_order: sliders.value
    }
    let resp = await homeSliderService.updateOrder(data);
    getAlert(resp.process);
};

</script>

<template>
    <section>

        <div class="col-md-12">
            <!-- begin::modal ADD SLIDER-->
            <div id="add-slider" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Imagen Deslizable</h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!--  start card -->

                            <!-- begin::form-->
                            <form id="crudForm" class="crudForm">
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">Título:</label>
                                            <div id="editor" class="mb-4"></div>
                                            <!--- Aquí deberían poder hacer el salto de línea un Rich-Text-->
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">Texto Breve:</label>
                                            <input v-model="vModel.description" name="description" class="form-control"
                                                   id="" type="text"
                                                   placeholder="Breve texto"/>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Nombre del Botón:</label>
                                            <input v-model="vModel.title_button" name="title_button"
                                                   class="form-control" id="" type="text"
                                                   placeholder="Llamado a la acción"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">URL del Botón:</label>
                                            <input v-model="vModel.url_button" name="url_button" class="form-control"
                                                   id="" type="text"
                                                   placeholder="¿A dónde dirige el botón?"/>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group  col-md-12">
                                            <label class="ul-form__label" for="">
                                                Imagen Full HD para Computadoras (1110 px * 440 px):
                                            </label>
                                            <div v-if="vModel.img_full">
                                                <img :src="'/images/slides/'+vModel.img_full" class="img-fluid"/>
                                                <input type="hidden" name="current_img_full" :value="vModel.img_full">
                                            </div>
                                            <div class="input-group mb-12">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"
                                                          id="inputGroupFileAddon01">Cargar</span>
                                                </div>
                                                <div class="custom-file">
                                                    <input name="img_full" class="custom-file-input"
                                                           id="inputGroupFile01" type="file"
                                                           aria-describedby="inputGroupFileAddon01"/>
                                                    <label class="custom-file-label" for="inputGroupFile01">
                                                        Elegir Imagen *.JPEG
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group  col-md-12">
                                            <label class="ul-form__label" for="">
                                                Imagen para Celulares (510 px * 395 px):
                                            </label>
                                            <div v-if="vModel.img_mobile">
                                                <img :src="'/images/slides/'+vModel.img_mobile" class="img-fluid"/>
                                                <input type="hidden" name="current_img_mobile"
                                                       :value="vModel.img_mobile">
                                            </div>
                                            <div class="input-group mb-12">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"
                                                          id="inputGroupFileAddon01">Cargar</span>
                                                </div>
                                                <div class="custom-file">
                                                    <input name="img_mobile" class="custom-file-input"
                                                           id="inputGroupFile01" type="file"
                                                           aria-describedby="inputGroupFileAddon01"/>
                                                    <label class="custom-file-label" for="inputGroupFile01">
                                                        Elegir Imagen *.JPEG
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <!--  end::form -->
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                            <button class="btn btn-success ml-2" type="button" @click="onSave()">Añadir</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::modal ADD Slider-->

            <!--  Modal IMAGE -->
            <div class="modal fade" id="show-image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Imagen ID: <strong v-if="modalImg.img" class="text-danger">{{ modalImg.img }}</strong>
                            </h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <img v-if="modalImg.img" :src="'/images/slides/'+modalImg.img" class="img-fluid"/>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header text-right bg-transparent">
                    <button class="btn btn-warning btn-md m-1" type="button" @click="enableDragAndDrop()" v-if="$hasPermission('home_slider.reorder')">
                        <i class="fa fa-sort-amount-down mr-2"></i> Reordenar
                    </button>
                    <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                            data-target="#add-slider" v-if="$hasPermission('home_slider.create')">
                        <i class="fa fa-clone text-white mr-2"></i> Agregar Imagen Deslizable
                    </button>
                </div>
                <div class="card-body">
                    <div class="card-title mb-3">
                        <i class="fa fa-clone"></i> Sección I - Imágenes deslizables
                    </div>
                    <p>Aquí encontrarás cada una de las plantillas que te guiarán en el diseño de nuevas
                        imágenes deslizables, da click para descargar la <a href="#" target="_blank"
                                                                            class="text-danger"> plantilla para
                            computadores</a> y la <a href="#" target="_blank" class="text-danger"> plantilla
                            para celulares</a></p>
                    <div class="table-responsive table-striped">
                        <table class="display table" id="ul-contact-list" style="width:100%">
                            <thead>
                            <tr>
                                <th v-if="dragAndDropEnabled">Arrastrar</th>
                                <th>ID</th>
                                <th>Thumbnail</th>
                                <th>Título</th>
                                <th>Texto Breve</th>
                                <th>Nombre Botón</th>
                                <th>URL Botón</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!--- Inicia ITEM-->
                            <template v-for="(slide,index) in sliders" :key="slide.id">
                                <tr class="drag"
                                    :data-index="index"
                                    :draggable="false"
                                    @dragstart="handleDragStart(index)"
                                    @dragover.prevent="handleDragOver"
                                    @drop="handleDrop(index)"
                                    @dragend="handleDragEnd"
                                    :class="(dragAndDropEnabled)?'pointerCursor':''">
                                    <td class="form-group col-md-2" v-if="dragAndDropEnabled">
                                        <i class="fa fa-sort-amount-down"></i>
                                    </td>
                                    <td style="padding-top: 40px;">{{ slide.id }}</td>
                                    <td>
                                        <a href="javascript:void(0)">
                                            <!----Se muestra la imágen de celulares como THUMBNAIL-->
                                            <div class="ul-widget-app__profile-pic">
                                                <img class="avatar-lg" :src="'/images/slides/'+slide.img_mobile"/>
                                            </div>
                                        </a>
                                    </td>
                                    <td style="padding-top: 40px;" v-html="slide.title "></td>
                                    <td style="padding-top: 40px;">{{ slide.description }}</td>
                                    <td style="padding-top: 40px;">{{ slide.title_button }}</td>
                                    <td style="padding-top: 40px;">
                                        <a :href="slide.url_button" target="_blank">
                                            Click para abrir
                                        </a>
                                    </td>
                                    <td style="padding-top: 28px;">
                                        <!-- BOTÓN MODAL IMAGEN Ful-->
                                        <button class="btn btn-warning btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#add-slider" title="Editar Deslizable"
                                                @click="onUpdate(slide)" v-if="$hasPermission('home_slider.edit')">
                                            <i class="fa fa-edit text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL IMAGEN Full-->
                                        <button class="btn btn-primary btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#show-image" title="Ver imagen Computadores"
                                                @click="openModalImg(slide.img_full)">
                                            <i class="fa fa-desktop text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL Imagen Medium-->
                                        <button class="btn btn-primary btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#show-image" title="Ver imagen Celuares"
                                                @click="openModalImg(slide.img_mobile)">
                                            <i class="fa fa-mobile-alt text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL QUITAR RESPONSABLE-->
                                        <button class="btn btn-danger btn-md m-1" type="button"
                                                title="Eliminar Deslizable" v-if="$hasPermission('home_slider.delete')"
                                                @click="onDelete(slide.id)">
                                            <i class="fa fa-trash text-white"></i>
                                        </button>
                                    </td>
                                </tr>
                            </template>
                            <!--- Termina Item-->

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>
.pointerCursor {
    cursor: pointer;
}

#editor {
    height: 250px; /* Ajusta este valor a la altura que desees */
}
</style>
