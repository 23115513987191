import {BaseService} from "./base.service";

class UsersService extends BaseService {

    constructor() {
        super('api/administrators')
    }

}

export const usersService = new UsersService();
