<script setup>
import {ref, onMounted} from 'vue';
import {gralHook} from "../../../../Hooks/gralHook";
import {homeSettingsService} from "../../../../services/HomeSettings.service";
import Quill from 'quill';
import "quill/dist/quill.snow.css";

let quill = null;

const {getAlert, valFormularios} = gralHook();

const vModelDif = ref({
    id: 0,
    title: '',
    description: '',
    icon: '',
    data: {}
});

const differentiators = ref([]);

const openModal = (group, index) => {

    quill.root.innerHTML = group[0]['value'];

    vModelDif.value = {
        id: index, // Asegúrate de que este es el campo correcto para el id
        description: group[1]['value'],
        icon: group[2]['value'],
        data: {}
    };
    //console.log(vModelDif.value);

};

const updateDifferentiator = async () => {

    //ACTUALIZO EL VALOR DEL TITULO EDITADO EN EL RICH TEXT
    vModelDif.value.data[vModelDif.value.id + '_title'] = quill.root.innerHTML;
    vModelDif.value.data[vModelDif.value.id + '_description'] = vModelDif.value.description;
    vModelDif.value.data[vModelDif.value.id + '_icon'] = vModelDif.value.icon;
    console.log('enviando- ', vModelDif.value.data)
    let response = await homeSettingsService.updateDifferentiator(vModelDif.value.data);
    getAlert(response.process);
};

const getRelevantInfo = async () => {
    differentiators.value = await homeSettingsService.getDifferentiator();
};

onMounted(async () => {
    await getRelevantInfo();
    quill = new Quill("#editor_dif", {
        theme: "snow",
    });
});
</script>

<template>
    <section>

        <!-- begin::modal EDIT SQUARE-->
        <div id="edit-square" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Editar Diferenciador</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="action">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Título:</label>
                                        <div id="editor_dif" class="mb-4"></div>

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Texto Breve:</label>
                                        <input v-model="vModelDif.description" class="form-control" id="" type="text"
                                               placeholder="Breve texto"/>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Nombre del Icono de <a
                                            href="https://fontawesome.com/v5/search?m=free" target="_blank"
                                            class="text-danger"> Font Awesome (Click aquí para ver catálogo de
                                            iconos):</a></label>
                                        <input v-model="vModelDif.icon" class="form-control" id="" type="text"
                                               placeholder="Nombre sin espacios o caracteres especiales, SÓLO GUIONES"/>
                                    </div>
                                </div>


                            </div>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="updateDifferentiator()">Actualizar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal Edit SQUARE-->

        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="card-title mb-3"><i class="fa fa-award"></i> Sección II - Diferenciadores</div>
                    <p>Aquí encontrarás los iconos disponibles para usar en esta sección: <a
                        href="https://fontawesome.com/v5/search?m=free" target="_blank" class="text-danger"> Ver
                        Iconos,</a> sólo escribe el nombre que viene debajo de cada icono en el campo correspondiente
                    </p>
                    <div class="table-responsive table-striped">
                        <table class="display table" id="ul-contact-list" style="width:100%">
                            <thead>
                            <tr>
                                <th>Icono</th>
                                <th>Título</th>
                                <th>Texto Breve</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(group, index) in differentiators" :key="index">
                                <tr>
                                    <td style="font-size: 3em;">
                                        <i :class="'fa fa-' + group[2]['value']"></i>
                                    </td>
                                    <td style="padding-top: 30px;" v-html="group[0]['value']">

                                    </td>
                                    <td style="padding-top: 30px;">
                                        {{ group[1]['value'] }}
                                    </td>
                                    <td style="padding-top: 28px;">
                                        <button class="btn btn-warning btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#edit-square" title="Editar Deslizable"
                                                @click="openModal(group, (index))" v-if="$hasPermission('differentiator.edit')">
                                            <i class="fa fa-edit text-white"></i>
                                        </button>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>
#editor_dif {
    height: 250px; /* Ajusta este valor a la altura que desees */
}
</style>
