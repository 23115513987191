import {BaseService} from "./base.service";

class SettingsService extends BaseService {
    constructor() {
        super('api/settings');
    }

    updateMultiple(settings) {
        const promises = Object.entries(settings).map(([key, value]) => {
            return this.updateOne(key, {value});
        });

        return Promise.all(promises);
    }
}

export const settingsService = new SettingsService();
