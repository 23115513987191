import {BaseService} from "./base.service";

class BrandsService extends BaseService {

    constructor() {
        super('api/brands')
    }

    updateOrder(params) {
        return this.http.post('api/update-order-brand', params).then(response => response.data).catch(err => err.message);
    }

    filterTrash() {
        return this.http.get('api/get-trash-brand').then(response => response.data).catch(err => err.message);
    }

    restoreTrash(id) {
        return this.http.get(`api/restore-trash-brand/${id}`).then(response => response.data).catch(err => err.message);
    }

}

export const brandsService = new BrandsService();
