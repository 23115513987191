import {BaseService} from "./base.service";

class SettingFaqsService extends BaseService {

    constructor() {
        super('api/setting-faqs')
    }
    getFaqs() {
        return this.http.get('api/get-faqs').then(response => response.data).catch(err => err.message);

    }

}

export const settingFaqService = new SettingFaqsService();
