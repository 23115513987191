import {BaseService} from "./base.service";

class VsProductSpecificationsService extends BaseService {

    constructor() {
        super('api/vs_product_specifications')
    }

}

export const vsproductspecificationService = new VsProductSpecificationsService();
