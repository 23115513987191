<script setup>
import {ref, watchEffect} from "vue";
import {accountService} from "../../../services/Account.service";
import {gralHook} from "../../../Hooks/gralHook";
import {estadoService} from "../../../services/States.service";
import {citiesService} from "../../../services/Cities.service";
import {clientsService} from "../../../services/Clients.service";

const {getAlert} = gralHook();
const props = defineProps({
    clientId: {
        type: [Number],
        default: null,
    },

});
const client_id = ref(0);
const selectedStateId = ref(0);
const selectedCityId = ref(0);
const address = ref([]);
const estados = ref([]);
const cities = ref([]);
const selectedAddress = ref({
    id: 0,
    address: '',
    neighborhood: '',
    zip_code: '',
    city: 0,
    state: 0,
    contact_phone: '',
}); // Añade esta línea
const selectedAddressId = ref(0); // Añade esta línea
const showForm = ref(false);

//METHODS
const onIndex = async () => {
    address.value = await accountService.getAddressByClient(client_id.value);
    estados.value = await estadoService.getMany();
};

const loadAddressSelected = (event) => { // Añade el parámetro event

    showForm.value = true;
    //console.log(event.target.value, address.value);
    const selectedId = Number(event.target.value);
    // Convierte selectedId a un número
    selectedAddress.value = address.value.find(add => add.id == selectedId);
    console.log(selectedAddress.value);
    selectedAddressId.value = selectedId;
    selectedStateId.value = selectedAddress.value.rel_state.id;
    selectedCityId.value = selectedAddress.value.rel_city.id;
    selectCity();
};

const updateAddressOrder = async () => {
    //console.log('updateAddressOrder = ', selectedAddress.value);
    selectedAddress.value.state = selectedStateId.value;
    selectedAddress.value.city = selectedCityId.value;
    let rest = await clientsService.updateAddress(selectedAddress.value.id, selectedAddress.value);
    setEmpty();
    getAlert(rest.process);
};

const selectCity = async () => {
    cities.value = await citiesService.getCityByState(selectedStateId.value);
};

watchEffect(async () => {
    client_id.value = props.clientId;
    await onIndex();
});

const setEmpty = () => {
    selectedAddress.value = {
        id: 0,
        address: '',
        neighborhood: '',
        zip_code: '',
        city: 0,
        state: 0,
        contact_phone: '',
        contact_name: '',
    };
    selectedAddressId.value = 0;
    showForm.value = false;
};

</script>

<template>
    <!-- begin::modal CHANGE Address-->
    <div id="order-address" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Dirección de Envío</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="setEmpty()"><span
                        aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <!-- begin::form-->

                    <div class="row">
                        <div class="form-group col-md-12">
                            <label class="ul-form__label" for="">Desea cambiar la dirección del Pedido:</label>
                            <select class="form-control" @change="loadAddressSelected">
                                <option value="">Seleccione una dirección</option>
                                <option v-for="add in address" :key="add.id" :value="add.id">
                                    {{ add.identifier }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!--  end::form -->
                    <!--  start card -->

                    <form v-if="showForm" id="crudForm">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for=""><strong>Calle</strong></label>
                                    <input name="address" type="text" class="form-control"
                                           v-model="selectedAddress.address" id="address"
                                           placeholder="Calle" value="">
                                </div>

                                <div class="form-group col-6">
                                    <label for=""><strong>Número Exterior</strong></label>
                                    <input name="exterior_number" type="text" class="form-control required"
                                           id="exterior_number"
                                           placeholder="Número Exterior" v-model="selectedAddress.exterior_number">
                                </div>
                                <div class="form-group col-6">
                                    <label for=""><strong>Número Interior</strong></label>
                                    <input name="interior_number" type="text" class="form-control" id="interior_number"
                                           placeholder="Número Interior" v-model="selectedAddress.interior_number">
                                </div>

                                <div class="form-group col-md-12">
                                    <label class="ul-form__label" for="">Colonia:</label>
                                    <input type="text" name="neighborhood" v-model="selectedAddress.neighborhood"
                                           class="form-control"
                                           id="neighborhood"
                                           placeholder="Colonia">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="ul-form__label" for="">C.P.:</label>
                                    <input type="text" name="zip_code" v-model="selectedAddress.zip_code"
                                           class="form-control"
                                           id="zip_code"
                                           placeholder="C.p">

                                </div>
                                <div class="form-group col-md-6">
                                    <label class="ul-form__label" for="">Estado:</label> <br>
                                    <select class="form-control" v-model="selectedStateId" @change="selectCity()">
                                        <option value="">Seleccione un estado</option>
                                        <option v-for="edo in estados" :key="edo.id" :value="edo.id">
                                            {{ edo.estado }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="ul-form__label" for="">Ciudad:</label> <br>
                                    <select class="form-control" v-model="selectedCityId" id="">
                                        <option value="">Seleccione una ciudad</option>
                                        <option v-for="city in cities" :key="city.id" :value="city.id">
                                            {{ city.ciudad }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-12">
                                    <label class="ul-form__label" for=""><strong>Nombre del Contacto en el
                                        Domicilio</strong></label>
                                    <input name="contact_name" type="text" class="form-control"
                                           id="contact_name"
                                           placeholder="Nombre"
                                           value="" v-model="selectedAddress.contact_name">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="ul-form__label" for="">Teléfono:</label>
                                    <input type="text" name="contact_phone" v-model="selectedAddress.contact_phone"
                                           class="form-control"
                                           id="contact_phone"
                                           placeholder="Teléfono">

                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal" @click="setEmpty()">Cerrar</button>
                    <button class="btn btn-success ml-2" type="button" @click="updateAddressOrder">Actualizar</button>

                </div>
            </div>
        </div>
    </div>
    <!-- end::modal CHANGE Address-->
</template>

<style scoped>

</style>
