<script setup>
import {onMounted, ref} from 'vue';

const props = defineProps({
    componentName: {type: String, required: true},
    id: {type: [Number, String], required: false, default: null},
});

let dynamicProps = ref({});

onMounted(() => {

    //console.log(props.componentName, ' - ', props.id);
    switch (props.componentName) {
        case 'edit_product':
            dynamicProps.value = {
                productId: +props.id
            };
            break;
        case 'order_detail':
            dynamicProps.value = {
                orderId: +props.id
            };
            break;
        case 'edit_info_page':
            dynamicProps.value = {
                pageId: +props.id
            };

            //console.log(dynamicProps.value);
            break;
    }

});
</script>
<template>
    <component :is="props.componentName" v-bind="dynamicProps"></component>
</template>
