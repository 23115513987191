import {BaseService} from "./base.service";

class SparesService extends BaseService {

    constructor() {
        super('api/spares')
    }

    getProductsSpares() {
        return this.http.get('api/get-products-spares').then(response => response.data).catch(err => err.message);
    }

}

export const sparesService = new SparesService();
