import {BaseService} from "./base.service";

class SubscriptionsService extends BaseService {

    constructor() {
        super('api/subscriptions')
    }

}

export const subscriptionsService = new SubscriptionsService();
