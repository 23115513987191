import {BaseService} from "./base.service";

class GalleryProductsService extends BaseService {

    constructor() {
        super('api/gallery-products')
    }

    getTheGalleryProducts(id) {
        return this.http.get(`api/get-the-gallery/${id}`).then(response => response.data).catch(err => err.message);
    }

    setMainImage(productId, idImage) {
        return this.http.get(`api/set-main-image/${productId}/${idImage}`).then(response => response.data).catch(err => err.message);
    }

    deleteImage(idFolder, idImage) {
        return this.http.delete(`api/gallery-products/${idFolder}/${idImage}`).then(response => response.data).catch(err => err.message);
    }

}

export const galleryProductsService = new GalleryProductsService();
