<script setup>
import {ref, onMounted, nextTick, watch} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {specificationsService} from "../../../services/Specifications.service.js";
import {vsproductspecificationService} from "../../../services/VsProductSpecifications.service";

const {getAlert, valFormularios, goBack} = gralHook();

const props = defineProps({
    product_id: {
        type: Number,
        required: true,
    }
});
const order = ref([]);
const specifications_product = ref([]);
const specifications = ref([]);
const fields = ref([{specification_id: 0, description: '', order: 0}]);
const draggedItem = ref(null);
const items = ref([]);
const dragAndDropEnabled = ref(false);

//METHODS PERSONALIZES
const addFields = () => {
    const lastField = fields.value[fields.value.length - 1];
    if (!lastField || (lastField.specification_id !== 0 && lastField.description !== '' && fields.value.length < specifications.value.length)) {
        fields.value.push({specification_id: 0, description: '', order: fields.value.length});
    } else {
        getAlert('empty_spec');
    }
};

const isSpecificationSelected = (id, index) => {
    //console.log('/-id', id, 'index', index, 'fields', fields.value)
    return fields.value.some((field, i) => i !== index && field.specification_id === id);
};

const onSave = async () => {
    let dataToSend = {
        fields: fields.value,
    };
    let resp;
    resp = valFormularios('crudFormSpe');

    if (resp) {
        //console.log('fields', fields.value);
        resp = await vsproductspecificationService.updateOne(props.product_id, dataToSend, {isForm: false});
        getAlert(resp.process);
    }

};

const removeField = (index) => {
    fields.value.splice(index, 1);
};

const updateField = (index, event) => {
    //console.log('index', index, ' - fields.value[index].specification_id ', fields.value[index].specification_id ,' - event', event.target.value);
    fields.value[index].specification_id = +event.target.value;
    //selectedSpecifications.value.push(event.target.value);
};

//METODOS DE DRAG AND DROP
const enableDragAndDrop = () => {
    dragAndDropEnabled.value = !dragAndDropEnabled.value;
    const items = document.querySelectorAll('[draggable]');
    items.forEach(item => {
        item.setAttribute('draggable', true);
    });
};

const handleDragStart = (index) => {
    //console.log('handleDragStart', index);
    draggedItem.value = index;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDrop = (index) => {
    const droppedItem = fields.value[draggedItem.value];
    fields.value = fields.value.filter((item, i) => i !== draggedItem.value);
    fields.value.splice(index, 0, droppedItem);
    draggedItem.value = null;
    //console.log('fieldsDrop', fields.value);
    // Actualizar el orden de los elementos en el array fields
    fields.value = fields.value.map((field, index) => ({ ...field, order: index }));
};

const handleDragEnd = () => {
    draggedItem.value = null;
};

const setOrder = (index, field) => {
    field.order = index;
    return false; // Este valor se utiliza para que el div no se renderice
};

//METODOS DE VIEW
watch(fields, () => {
    nextTick();
}, {deep: true});

onMounted(async () => {
    //CARGAMOS TODAS LAS ESPECIFICACIONES
    specifications.value = await specificationsService.getMany();
    //CARGAMOS LAS ESPECIFICACIONES D 1 PRODUCTO EN PARTICULAR
    if (props.product_id !== 0) {
        let response = await vsproductspecificationService.getOne(props.product_id);
        specifications_product.value = Array.isArray(response) ? response : [response];


        // Llenar fields con las especificaciones del producto
        fields.value = specifications_product.value.map(specification_product => ({
            specification_id: specification_product.specification_id,
            description: specification_product.description,
            order: specification_product.order
        }));
    }
});

</script>

<template>
    <div class="">
        <div class="card-title mb-3"><i class="fa fa-list"></i> Especificaciones del Producto</div>
        <form id="crudFormSpe" class="crudFormSpe">
            <div class="row">
                <div class="form-group col-md-2" v-if="dragAndDropEnabled">
                    <label class="ul-form__label" for="">Drag&Drop:</label>
                </div>
                <div class="form-group col-md-3">
                    <label class="ul-form__label" for="">Característica:</label>
                </div>
                <div class="form-group col-md-6">
                    <label class="ul-form__label" for="">Descripción:</label>
                </div>
                <div class="form-group col-md-1">
                    <button class="btn btn-success ml-2" type="button" @click="addFields" v-if="$hasPermission('specification_product.create')">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <!--- ITEM Start-->
            <template v-for="(field, index) in fields" :key="index">

                <div class="row"
                     :data-index="index"
                     :draggable="false"
                     @dragstart="handleDragStart(index)"
                     @dragover.prevent="handleDragOver"
                     @drop="handleDrop(index)"
                     @dragend="handleDragEnd"
                     :class="(dragAndDropEnabled)?'pointerCursor':''">
                    <div class="form-group col-md-2" v-if="dragAndDropEnabled">
                        <i class="fa fa-sort-amount-down"></i>
                    </div>
                    <div class="form-group col-md-3">
                        <select class="form-control" data-select="on" v-model="field.specification_id"
                                @change="updateField(index, $event)">
                            <option :value="0">Seleccionar</option>
                            <option v-for="specification in specifications" :key="specification.id"
                                    :value="specification.id"
                                    :disabled="isSpecificationSelected(specification.id, index)">
                                {{ specification.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <input class="form-control required" v-model="field.description" type="text" placeholder="Describa la especificación de este producto"/>
                        <small class="ul-form__text form-text"></small>
                    </div>
                    <div class="form-group col-md-1">
                        <button class="btn btn-danger ml-2" type="button" @click="removeField(index)" v-if="index > 0">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                </div>

            </template>
            <!-- Item End-->
            <div class="modal-footer my-4"><!--- Línea División-->
                <button class="btn btn-warning btn-md m-1" type="button" @click="enableDragAndDrop()" v-if="$hasPermission('specification_product.reorder')">
                    <i class="fa fa-sort-amount-down mr-2"></i> Reordenar
                </button>
                <button class="btn btn-success ml-2" type="button" @click="onSave()" v-if="$hasPermission('specification_product.store')">
                    <i class="fa fa-tag text-white mr-2"></i> Guardar
                </button>
            </div>
        </form>
    </div>
</template>

<style scoped>
    .pointerCursor {
        cursor: pointer;
    }
</style>
