import {BaseService} from "./base.service";

class CategoriesService extends BaseService {

    constructor() {
        super('api/categories')
    }

    updateOrder(params) {
        return this.http.post('api/update-order-category', params).then(response => response.data).catch(err => err.message);
    }

    filterTrash() {
        return this.http.get('api/get-trash-category').then(response => response.data).catch(err => err.message);
    }

    restoreTrash(id) {
        return this.http.get(`api/restore-trash-category/${id}`).then(response => response.data).catch(err => err.message);
    }

}

export const categoriesService = new CategoriesService();
