<script setup>
import {ref, onMounted} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {ordersService} from "../../../services/Orders.service";
import changeStatusOrder from "../../../components/Manager/widgets/ChangeStatus.vue";
import comments from "../widgets/Comments.vue";

//DATA
const {formatDate, getAlert} = gralHook();
const ordersList = ref([]);
const client_notes = ref('');
const admin_notes = ref('');
const order_id = ref();
const order_search_id = ref('');
const status_order_search = ref(0);
const client_search = ref('');
const params = ref({
    order_id: '',
    client_id: '',
    status_order: ''
});

//METHODS
const onIndex = async () => {
    ordersList.value = await ordersService.getMany();
};

const getClientNote = async (order) => {
    order_id.value = Number(order.id);
    client_notes.value = order.client_note;
    admin_notes.value = order.admin_note;
};

const changeStatusOrderId = (orderId) => {
    order_id.value = orderId;
};

const findOrder = async () => {

    if (order_search_id.value !== '') {
        params.value.order_id = order_search_id.value;
    }

    if (client_search.value !== '') {
        params.value.client_id = client_search.value;
    }

    if (status_order_search.value !== 0) {
        params.value.status_order = status_order_search.value;
    }

    ordersList.value = await ordersService.getMany(params.value);
};

onMounted(async () => {
    await onIndex();
});
</script>

<template>
    <div class="orders-component">

        <comments :admin_notes="admin_notes" :client_notes="client_notes" :order-id="order_id"></comments>

        <changeStatusOrder :order-id="order_id"></changeStatusOrder>

        <div class="main-content">
            <div class="breadcrumb">
                <h1 class="mr-2">Lista de Pedidos</h1>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="card mb-5">
                        <div class="card-body">
                            <div class="row" style="padding-left: 20px;"> Filtros</div>
                            <div class="row row-xs my-3">
                                <div class="col-md-3 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="order_search_id"
                                               placeholder="Order id"/>
                                        <small class="ul-form__text form-text"></small>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="client_search"
                                               placeholder="Cliente"/>
                                        <small class="ul-form__text form-text"> </small>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <select class="form-control" v-model="status_order_search">
                                            <option :value="0">Todas</option>
                                            <option :value="1">Pendientes de Pago</option>
                                            <option :value="2">En Revisión de Pago</option>
                                            <option :value="3">Preparando Pedido</option>
                                            <option :value="4">Listo para Enviar</option>
                                            <option :value="5">Enviado</option>
                                            <option :value="6">Anulado</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-3 mt-md-0">
                                    <button class="btn btn-primary btn-block" @click="findOrder()">Filtrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="contact-list">
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <div class="text-left">
                            <div class="card-body">
                                <div class="table-responsive table-striped">
                                    <table class="display table" id="ul-contact-list" style="width:100%">
                                        <thead>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Fecha Compra</th>
                                            <th>Cliente</th>
                                            <th>Estado</th>
                                            <th>Teléfono</th>
                                            <th>Total</th>
                                            <th>Estatus</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <!--- Inicia ITEM-->
                                        <template v-for="order in ordersList" :key="order.id">
                                            <tr>
                                                <td style="padding-top: 20px;">
                                                    <a href="javascript:void(0)">
                                                        <i class="fa fa-search"></i>
                                                        {{ order.order_id }}
                                                    </a>
                                                </td>
                                                <td style="padding-top: 20px;">{{ formatDate(order.created_at) }}</td>
                                                <td style="padding-top: 20px;">
                                                    <small class="text-info">{{ order.id }} - </small>
                                                    {{ order.rel_client?.names }} {{ order.rel_client?.lastnames }}
                                                </td>
                                                <td style="padding-top: 20px;">{{
                                                        order.client_address?.rel_state.estado
                                                    }}
                                                </td>
                                                <td style="padding-top: 20px;">
                                                    {{ order.rel_client?.phone }}
                                                </td>
                                                <td style="padding-top: 20px;"><strong class="text-primary">$ </strong>
                                                    {{ order.total }}
                                                </td>
                                                <td>
                                                    <div v-if="order.status_order=='1'">
                                                        <span class="badge badge-pill badge-outline-warning p-2 m-1">
                                                            <i class="fa fa-money"></i>
                                                        </span>
                                                        Pendiente de Pago
                                                    </div>
                                                    <div v-if="order.status_order=='2'">
                                                        <span class="badge badge-pill badge-outline-warning p-2 m-1">
                                                            <i class="fa fa-search-dollar"></i>
                                                        </span>
                                                        En revisión de Pago
                                                    </div>
                                                    <div v-if="order.status_order=='3'">
                                                       <span class="badge badge-pill badge-outline-primary p-2 m-1">
                                                            <i class="fa fa-box-open"></i>
                                                        </span>
                                                        Preparando Pedido
                                                    </div>
                                                    <div v-if="order.status_order=='4'">
                                                       <span class="badge badge-pill badge-outline-primary p-2 m-1">
                                                           <i class="fa fa-dolly"></i>
                                                        </span>
                                                        Listo para Enviar
                                                    </div>
                                                    <div v-if="order.status_order=='5'">
                                                       <span class="badge badge-pill badge-outline-success p-2 m-1">
                                                           <i class="fa fa-truck"></i>
                                                        </span>
                                                        Enviado
                                                    </div>
                                                    <div v-if="order.status_order=='5'">
                                                       <span class="badge badge-pill badge-outline-danger p-2 m-1">
                                                           <i class="fa fa-trash"></i>
                                                        </span>
                                                        Cancelado
                                                    </div>
                                                </td>
                                                <td>
                                                    <!-- BOTÓN MODAL Ver comentarios-->
                                                    <button class="btn btn-info btn-md m-1" type="button"
                                                            data-toggle="modal" data-target="#comments"
                                                            title="Ver Comentarios"
                                                            @click="getClientNote(order)" v-if="$hasPermission('orders.add_note')">
                                                        <i class="fa fa-comments text-white"></i>
                                                    </button>
                                                    <!-- BOTÓN MODAL Cambiar Estatus-->
                                                    <button class="btn btn-warning btn-md m-1" type="button"
                                                            data-toggle="modal" data-target="#order-status"
                                                            title="Cambiar Estatus"
                                                            @click="changeStatusOrderId(order.id)" v-if="$hasPermission('orders.change_status')">
                                                        <i class="fa fa-random text-white"></i>
                                                    </button>
                                                    <!-- BOTÓN MODAL Revisar Orden-->
                                                    <a :href="'/manager/detalle-de-pedido/'+order.id" v-if="$hasPermission('orders.edit')">
                                                        <button class="btn btn-warning btn-md m-1" type="button"
                                                                title="Ver Orden"><i
                                                            class="fa fa-edit text-white"></i>
                                                        </button>
                                                    </a>
                                                    <!-- BOTÓN MODAL Borrar Orden
                                                    <button class="btn btn-danger btn-md m-1" type="button"
                                                            data-toggle="modal" data-target="#delete-order"
                                                            title="Anular Pedido"><i class="fa fa-trash text-white"></i>
                                                    </button>-->
                                                </td>
                                            </tr>
                                        </template>
                                        <!--- Termina Item-->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- end of main-content -->
        </div>
    </div>
</template>

<style scoped>

</style>
