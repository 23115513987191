import {BaseService} from "./base.service";

class VsProductCategoryService extends BaseService {

    constructor() {
        super('api/vs_product_categories')
    }

}

export const vsproductcategoryService = new VsProductCategoryService();
