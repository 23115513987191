<script setup>
import {ref, onMounted, nextTick, watch} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {specificationsService} from "../../../services/Specifications.service.js";
import sweetAlert from "sweetalert2";
import {specificationsHeadersService} from "../../../services/SpecificationsHeaders.service";

const {getAlert, valFormularios, goBack} = gralHook();
const order = ref([]);
const specificationsHeaders = ref([]);
const specifications = ref([]);
const fields = ref([{specification: ''}]);
const draggedItem = ref(null);
const modificar = ref(false);
const dragAndDropEnabled = ref(false);
const header_selected_id = ref(0);
const vModel = ref({
    id: '',
});

//METHODS PERSONALIZES
const addFields = () => {
    //console.log('fields-concepts', fields.value);
    const lastField = fields.value[fields.value.length - 1];
    if (lastField.specification !== '') {
        fields.value.push({specification: ''});
    } else {
        getAlert('empty_spec_concept');
    }
};

const onSave = async () => {
    let dataToSend = {
        header_id: header_selected_id.value,
        fields: fields.value,
    };
    let resp;
    resp = valFormularios('crudFrom');

    if (resp) {
        if (modificar.value) {
            resp = await specificationsService.updateOne(vModel.value.id, dataToSend);
        } else {
            resp = await specificationsService.saveOne(dataToSend);
        }
        getAlert(resp.process, resp.error);
    }

};

const removeField = (index) => {
    fields.value.splice(index, 1);
};

const onUpdate = (index, data) => {
    //console.log('onUpdate', data);
    header_selected_id.value = data.header_id;
    fields.value[0].specification = data.name;
    vModel.value.id = data.id;
    //console.log('fields update', fields.value);
    modificar.value = true;
};

const onDelete = async (id) => {
    //verificar que no tengan hijos en la tabla vs_product_specifications, si es asi no se puede eliminar
    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp;
            resp = await specificationsService.deleteOne(id);
            getAlert(resp.process);
        }
    })

};

const onRestore = async (id) => {
    let resp;
    resp = await specificationsService.restoreTrash(id);
    getAlert(resp.process);
};

//METODOS DE DRAG AND DROP
const enableDragAndDrop = () => {
    dragAndDropEnabled.value = !dragAndDropEnabled.value;
    const items = document.querySelectorAll('[draggable]');
    items.forEach(item => {
        item.setAttribute('draggable', true);
    });
};

const handleDragStart = (index) => {
    //console.log('handleDragStart', index);
    draggedItem.value = index;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDrop = (index) => {
    const droppedItem = specifications.value[draggedItem.value];
    specifications.value = specifications.value.filter((item, i) => i !== draggedItem.value);
    specifications.value.splice(index, 0, droppedItem);
    draggedItem.value = null;
    //console.log('fieldsDrop', fields.value);
    // Actualizar el orden de los elementos en el array fields
    specifications.value = specifications.value.map((field, index) => ({...field, order: index}));
    console.log('fieldsDrop', specifications.value);
};

const handleDragEnd = async () => {
    await updateOrder();
    draggedItem.value = null;
};

const updateOrder = async () => {
    const data = {
        specification_fields: specifications.value
    }
    console.log('data', data);
    let resp = await specificationsService.updateOrder(data);
    getAlert(resp.process);
};

//METODOS DE VIEW
watch(fields, () => {
    nextTick();
}, {deep: true});

onMounted(async () => {
    //CARGAMOS TODAS LAS ESPECIFICACIONES
    specifications.value = await specificationsService.getMany();
    specificationsHeaders.value = await specificationsHeadersService.getMany();
    //CARGAMOS LAS ESPECIFICACIONES D 1 PRODUCTO EN PARTICULAR
});

</script>

<template>
    <!-- begin::modal ADD SPECIFICATIONS-->
    <div id="add-specifications" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Especificaciones Masivamente</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <!--  start card -->
                    <!-- begin::form-->
                    <form id="crudFrom" class="crudFrom">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="ul-form__label" for="">Tipo de Especificación:</label>
                                <div class="input-right-icon">
                                    <select class="form-control" data-select="on" v-model="header_selected_id">
                                        <option :value="0">Seleccionar</option>
                                        <option v-for="header in specificationsHeaders" :key="header.id"
                                                :value="header.id">
                                            {{ header.name }}
                                        </option>
                                    </select>
                                    <span class="span-right-input-icon">
                                        <i class="ul-form__icon fas fa-info-circle"></i>
                                    </span>
                                    <small class="ul-form__text form-text" id="">
                                        Seleccione un tipo de especificación
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-10">
                                <label class="ul-form__label" for="">Nombre:</label>
                            </div>
                            <div class="form-group col-md-1" v-if="!modificar">
                                <button class="btn btn-success ml-2" type="button" @click="addFields"><i
                                    class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <template v-for="(field, index) in fields" :key="index">
                            <!--- ITEM Start-->
                            <div class="row">
                                <div class="form-group col-md-10">
                                    <input class="form-control" v-model="field.specification" type="text"
                                           placeholder="Nombre de la Especificación"/><small
                                    class="ul-form__text form-text">
                                </small>
                                </div>
                                <div class="form-group col-md-1">
                                    <button class="btn btn-danger ml-2" type="button" @click="removeField(index)"><i
                                        class="fa fa-times"></i></button>
                                </div>
                            </div>
                            <!-- Item End-->
                        </template>

                    </form>
                    <!--  end::form -->
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                    <button class="btn btn-success ml-2" type="button" @click="onSave()">Añadir</button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6 mb-4">
        <div class="card text-left">
            <div class="card-header text-right bg-transparent">
                <button class="btn btn-warning btn-md m-1" type="button" @click="enableDragAndDrop()">
                    <i class="fa fa-sort-amount-down mr-2"></i> Reordenar
                </button>
                <button class="btn btn-success btn-md m-1"
                        type="button"
                        data-toggle="modal"
                        data-target="#add-specifications">
                    <i class="fa fa-plus text-white mr-2"></i>
                    Agregar Especificaciones
                </button>
            </div>
            <div class="card-body">
                <div class="table-responsive table-striped">
                    <table class="display table" id="ul-contact-list" style="width:100%">
                        <thead>
                        <tr>
                            <th v-if="dragAndDropEnabled">Arrastrar</th>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Tipo Especificación</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        <!--- Inicia ITEM-->
                        <template v-for="(spec,index) in specifications" :key="spec.id">
                            <tr class="drag"
                                :data-index="index"
                                :draggable="false"
                                @dragstart="handleDragStart(index)"
                                @dragover.prevent="handleDragOver"
                                @drop="handleDrop(index)"
                                @dragend="handleDragEnd"
                                :class="(dragAndDropEnabled)?'pointerCursor':''">
                                <td class="form-group col-md-2" v-if="dragAndDropEnabled">
                                    <i class="fa fa-sort-amount-down"></i>
                                </td>
                                <td style="padding-top: 40px;">{{ spec.id }}</td>
                                <td style="padding-top: 40px;">{{ spec.name }}</td>
                                <td style="padding-top: 40px;">{{ spec.header.name }}</td>
                                <td style="padding-top: 28px;">
                                    <!-- BOTÓN MODAL Editar -->
                                    <button class="btn btn-warning btn-md m-1" type="button"
                                            data-toggle="modal" data-target="#add-specifications"
                                            title="Editar Especificación" @click="onUpdate(index,spec)"><i
                                        class="fa fa-edit text-white"></i></button>
                                    <!-- BOTÓN MODAL Eliminar  Soft Delete-->
                                    <button class="btn btn-danger btn-md m-1" type="button"
                                            title="Eliminar Especificación" @click="onDelete(spec.id)"><i
                                        class="fa fa-trash text-white"></i></button>
                                </td>
                            </tr>
                        </template>
                        <!--- Termina Item-->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
.pointerCursor {
    cursor: pointer;
}
</style>
