import {BaseService} from "./base.service";

class HomeSettingsService extends BaseService {

    constructor() {
        super('api/home-settings')
    }

    getDifferentiator() {
        return this.http.get('api/get-differentiator').then(response => response.data).catch(err => err.message);
    }

    updateDifferentiator(data) {
        return this.http.post('api/update-differentiator', data).then(response => response.data).catch(err => err.message);
    }

    getBanner() {
        return this.http.get('api/get-banner').then(response => response.data).catch(err => err.message);
    }

    updateBanner( params, options = {}) {

        let config = {};
        let {
            isForm = false,
        } = options;

        if (isForm === true) {
            params.append("_method", 'PATCH');
            config = {
                method: "POST",
                headers: {'Content-Type': 'multipart/form-data', 'enctype': 'multipart/form-data'}
            }
            return this.http({
                method: "post",
                url: `api/update-banner/`,
                data: params,
                headers: config.headers
            }).then(response => response.data).catch(err => err.message);
        } else {
            //return this.http.patch(`api/update-banner/`, params, config).then(response => response.data).catch(err => err.message);
        }

    }

}

export const homeSettingsService = new HomeSettingsService();
