import {BaseService} from "./base.service";

class OrdersService extends BaseService {

    constructor() {
        super('api/orders')
    }

    saveAdminNote(id, data) {
        return this.http.post(`api/save-admin-note/${id}`, data).then(response => response.data).catch(err => err.message);
    }

    changeStatusOrder(id, data) {
        return this.http.post(`api/change-status-order/${id}`, data).then(response => response.data).catch(err => err.message);
    }

    changeAddressOrder(idAddress, idOrder) {
        return this.http.get(`api/change-address-order/${idAddress}/${idOrder}`).then(response => response.data).catch(err => err.message);
    }

}

export const ordersService = new OrdersService();
