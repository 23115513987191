<script setup>
import {ref, onMounted, reactive, nextTick} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {settingInfosService} from "../../../services/SettingInfos.service.js";
import sweetAlert from "sweetalert2";

//DATA
const {getAlert, valFormularios} = gralHook();
const getInfos = ref([]);
let modalImg = reactive({
    img: ''
});
//METHODS
const onIndex = async () => {
    getInfos.value = await settingInfosService.getListInfos();
};

const openModalImg = async (img) => {
    modalImg.img = img;
    await nextTick();
}

const onDelete = async (id) => {
    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await settingInfosService.deleteOne(id);
            getAlert(resp.process);
            await onIndex();
        }
    })
};

onMounted(async () => {
    await onIndex();
});

</script>

<template>
    <div class="main-content">
        <!--  Modal -->
        <div class="modal fade" id="show-image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Imagen: </h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <img v-if="modalImg.img" :src="'/images/banners/'+modalImg.img" class="img-fluid"/>
                        <!---image_banner-->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        <!--  Modal -->
        <div class="breadcrumb">
            <h1>Páginas de Infomación</h1>
            <ul>
                <li><a href="">Detalle</a></li>
                <li></li>
            </ul>
        </div>
        <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
        <section class="contact-list">
            <div class="row">
                <div class="col-md-12 mb-4">
                    <div class="card text-left">
                        <div class="card-header text-right bg-transparent">
                            <a href="/manager/agregar-pagina-informativa">
                                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                                        data-target="#add-user" v-if="$hasPermission('info_page.create')">
                                    <i class="fa fa-newspaper text-white mr-2"></i>
                                    Agregar Página de Información
                                </button>
                            </a>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive table-striped">
                                <table class="display table" id="ul-contact-list" style="width:100%">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Título de la Sección</th>
                                        <th>Título del Text</th>
                                        <th>URL</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <!--- Inicia ITEM-->
                                    <template v-for="info in getInfos" :key="info.id">
                                        <tr>
                                            <td style="padding-top: 20px;">{{ info.id }}</td>
                                            <td style="padding-top: 20px;">{{ info.title_section }}</td>
                                            <td style="padding-top: 20px;">{{ info.title }}</td>
                                            <td style="padding-top: 20px;">{{ info.slug }}</td>
                                            <td>
                                                <!-- BOTÓN MODAL Editar-->

                                                <a
                                                    v-if="$hasPermission('info_page.edit')"
                                                   :href="'editar-pagina-informativa/'+info.id"
                                                   class="btn btn-warning btn-md m-1"
                                                   title="Editar Información Relevante"><i
                                                    class="fa fa-edit text-white"></i></a>

                                                <!-- BOTÓN MODAL IMAGEN-->
                                                <button class="btn btn-primary btn-md m-1" type="button"
                                                        data-toggle="modal"
                                                        data-target="#show-image"
                                                        @click="openModalImg(info.image_banner)" title="Ver Banner "><i
                                                    class="fa fa-image text-white"></i></button>
                                                <!-- BOTÓN MODAL IMAGEN-->
                                                <button class="btn btn-primary btn-md m-1" type="button"
                                                        data-toggle="modal"
                                                        data-target="#show-image" title="Ver imagen "
                                                        @click="openModalImg(info.image_text)"><i
                                                    class="fa fa-image text-white"></i>
                                                </button>

                                                <!-- BOTÓN MODAL QUITAR Información Relevante-->
                                                <!--- ESTE BOTÓN DE ELIMINAR ESTÁ BLOQUEADO PARA LOS ID 1 Y ID 2 PORQUE SON LOS DE TERMINOS Y CONDICIONES Y AVISO DE PRIVACIDAD-->
                                                <button v-if="info.id!==1 && info.id!==2 && info.id!==3 && $hasPermission('info_page.delete')"
                                                        class="btn btn-danger btn-md m-1" type="button"
                                                        data-toggle="modal"
                                                        data-target="#delete-relevant-info"
                                                        @click="onDelete(info.id)"

                                                        title="Eliminar Información Relevante"><i
                                                    class="fa fa-trash text-white"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                    <!--- Termina Item-->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end of main-content -->
        <!-- end of main-content -->
    </div>
</template>

<style scoped>

</style>
