import {BaseService} from "./base.service";

class VsProductSparesService extends BaseService {

    constructor() {
        super('api/vs_product_spares')
    }

}

export const vsproductspareService = new VsProductSparesService();
