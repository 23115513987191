import {BaseService} from "./base.service";

class SeosService extends BaseService {

    constructor() {
        super('api/seos')
    }

}

export const seosService = new SeosService();
