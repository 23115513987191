import {BaseService} from "./base.service";

class PermissionsService extends BaseService {

    constructor() {
        super('api/permissions')
    }

    updateRolePermissions(roleId, permissions) {
        return this.http.post(`api/roles/${roleId}/permissions`, permissions).then(response => response.data).catch(err => err.message);;
    }

}

export const permissionsService = new PermissionsService();
