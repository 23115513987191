import {BaseService} from "./base.service";

class VsRelationProductService extends BaseService {

    constructor() {
        super('api/vs_relation_products')
    }

}

export const vsrelationproductService = new VsRelationProductService();
