import {BaseService} from "./base.service";

class FeaturedProductsService extends BaseService {

    constructor() {
        super('api/featured-products')
    }

}

export const featuredProductsService = new FeaturedProductsService();
