<script setup>
import {ref, onMounted, watch} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {brandsService} from "../../../services/Brands.service";
import sweetAlert from "sweetalert2";

const {getAlert, valFormularios, goBack, generateFriendlyUrl} = gralHook();
const brands = ref([]);
const vModel = ref({
    id: '',
    name: '',
    friendly_url: '',
});
const modificar = ref(false);
const draggedItem = ref(null);
const items = ref([]);
const dragAndDropEnabled = ref(false);
const trashList = ref(false);

watch(() => vModel.value.name, (newVal) => {
    vModel.value.friendly_url = generateFriendlyUrl(newVal);
});

const onRestore = async (id) => {

    let resp;
    resp = await brandsService.restoreTrash(id);
    getAlert(resp.process);

};

const onDelete = async (id) => {

    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp;
            resp = await brandsService.deleteOne(id);
            getAlert(resp.process);
        }
    })

};

const onSave = async () => {
    let resp;
    resp = valFormularios('crudForm');
    if (resp) {
        if (modificar.value) {
            resp = await brandsService.updateOne(vModel.value.id, vModel.value);
        } else {
            resp = await brandsService.saveOne(vModel.value);
        }
        getAlert(resp.process);
    }
};

const onUpdate = (data) => {
    vModel.value = data;
    modificar.value = true;
};

const onIndex = async () => {
    brands.value = await brandsService.getMany();
};

//METODOS DE DRAG AND DROP
const enableDragAndDrop = () => {
    dragAndDropEnabled.value = !dragAndDropEnabled.value;
    const items = document.querySelectorAll('[draggable]');
    items.forEach(item => {
        item.setAttribute('draggable', true);
    });
};

const handleDragStart = (index) => {
    //console.log('handleDragStart', index);
    draggedItem.value = index;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDrop = (index) => {
    const droppedItem = brands.value[draggedItem.value];
    brands.value = brands.value.filter((item, i) => i !== draggedItem.value);
    brands.value.splice(index, 0, droppedItem);
    draggedItem.value = null;
    brands.value = brands.value.map((field, index) => ({...field, order: index}));
};

const updateOrder = async () => {
    const data = brands.value.map((field, index) => ({
        id: field.id,
        order: index
    }));
    await brandsService.updateOrder({brands: data});
};

const handleDragEnd = async () => {
    draggedItem.value = null;
    await updateOrder();
};

const filterTrash = async () => {

    if (document.querySelector('.trashFilter').value == 1) {
        await onIndex();
        trashList.value = false;
    } else {
        trashList.value = true;
        brands.value = await brandsService.filterTrash();
    }

};

onMounted(async () => {
    await onIndex();
});
</script>

<template>
    <div id="add_tag_component">
        <!-- begin::modal ADD Category-->
        <div id="add-category" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Marca</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="crudForm" class="crudForm">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <!-- Este label va en Disabled al editar y debe ser irrepetible-->
                                        <label class="ul-form__label" for="">Nombre:</label>
                                        <input class="form-control" v-model="vModel.name" type="text"
                                               placeholder="Nombre del Usuario"/>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <!-- Este label va en Disabled al editar y debe ser irrepetible-->
                                        <label class="ul-form__label" for="">URL Amigable (No usar carácteres
                                            epeciales):</label>
                                        <input class="form-control" v-model="vModel.friendly_url" readonly type="text"
                                               placeholder="Puede llevar guiones - SIN carácteres especiales (, . + / & % ´´ ), sólo palabras, sin acentos, "/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" v-if="!modificar" type="button" @click="onSave()">Añadir
                        </button>
                        <button class="btn btn-success ml-2" v-else type="button" @click="onSave()">Actualizar</button>

                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal ADD Category-->
        <div class="main-content">
            <div class="breadcrumb">
                <h1 class="mr-2">Marcas</h1>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="card mb-5">
                        <div class="card-body">
                            <div class="row" style="padding-left: 20px;"> Filtros</div>
                            <div class="row row-xs my-3">
                                <div class="col-md-10 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <select class="form-control trashFilter" @change="filterTrash()">
                                            <option :value="1">Activas</option>
                                            <option :value="2">Eliminadas</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-3 mt-md-0">
                                    <button class="btn btn-primary btn-block">Mostrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="contact-list">
                <div class="row">
                    <div class="col-md-8 mb-4">
                        <div class="card text-left">
                            <div class="card-header text-right bg-transparent">
                                <button class="btn btn-warning btn-md m-1" type="button" @click="enableDragAndDrop()" v-if="$hasPermission('brands.reorder')">
                                    <i class="fa fa-sort-amount-down mr-2"></i> Reordenar
                                </button>
                                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal" data-target="#add-category" v-if="$hasPermission('brands.create')">
                                    <i class="fa fa-plus text-white mr-2"></i>
                                    Agregar Marca
                                </button>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-striped">
                                    <table class="display table" id="ul-contact-list" style="width:100%">
                                        <thead>
                                        <tr>
                                            <th v-if="dragAndDropEnabled">Arrastrar</th>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>URL Amigable</th>
                                            <th>Acciones</th>
                                            <th>Estatus</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(brand,index) in brands" :key="brand.id">
                                            <!--- Inicia ITEM-->
                                            <tr class="drag"
                                                :data-index="index"
                                                :draggable="false"
                                                @dragstart="handleDragStart(index)"
                                                @dragover.prevent="handleDragOver"
                                                @drop="handleDrop(index)"
                                                @dragend="handleDragEnd"
                                                :class="(dragAndDropEnabled)?'pointerCursor':''">

                                                <td class="form-group col-md-2" v-if="dragAndDropEnabled">
                                                    <i class="fa fa-sort-amount-down"></i>
                                                </td>
                                                <td style="padding-top: 40px;">{{ brand.id }}</td>
                                                <td style="padding-top: 40px;">{{ brand.name }}</td>
                                                <td style="padding-top: 40px;">{{ brand.friendly_url }}</td>
                                                <td style="padding-top: 28px;">

                                                    <!-- BOTÓN MODAL Editar -->
                                                    <button v-if="!trashList && $hasPermission('brands.edit')" class="btn btn-warning btn-md m-1"
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target="#add-category"
                                                            title="Editar Categoría" @click="onUpdate(brand)" >
                                                        <i class="fa fa-edit text-white"></i>
                                                    </button>
                                                    <!-- BOTÓN MODAL Eliminar  Soft Delete-->
                                                    <button v-if="!trashList && $hasPermission('brands.delete')" class="btn btn-danger btn-md m-1"
                                                            type="button"
                                                            data-toggle="modal" data-target="#delete-category"
                                                            title="Eliminar Categoría" @click="onDelete(brand.id)"><i
                                                        class="fa fa-trash text-white"></i></button>
                                                    <!-- Solo aparece en los Soft Delete BOTÓN MODAL Reactivar  Quitar Soft Delete-->
                                                    <button v-if="trashList && $hasPermission('brands.restore')" class="btn btn-success btn-md m-1"
                                                            type="button"
                                                            data-toggle="modal" data-target="#activate-category"
                                                            title="Reactivar Categoría" @click="onRestore(brand.id)">
                                                        <i class="fa fa-check text-white"></i>
                                                    </button>

                                                </td>
                                                <td class="text-success" style="padding-top: 40px;">Activa</td>
                                            </tr>
                                            <!--- Termina Item-->
                                        </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- end of main-content -->
        </div>
    </div>
</template>

<style scoped>
.pointerCursor {
    cursor: pointer;
}
</style>
