import {BaseService} from "./base.service";

class HomeRelevantInfoService extends BaseService {

    constructor() {
        super('api/home-relevant-info')
    }

}

export const homeRelevantInfoService = new HomeRelevantInfoService();
