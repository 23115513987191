import {BaseService} from "./base.service";

class RolesService extends BaseService {

    constructor() {
        super('api/roles')
    }

}

export const rolesService = new RolesService();
