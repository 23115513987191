import { authService } from './services/authService';

let userPermissions = [];

export const fetchUserPermissions = async () => {
    try {
        userPermissions = await authService.getUserPermissions();
    } catch (error) {
        console.error('Error fetching user permissions:', error);
        throw error;
    }
};

export const hasPermission = (permission) => {
    return userPermissions.includes(permission);
};
