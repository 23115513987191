<script setup>
import axios from "axios";
import {ref} from "vue";
import {subscriptionsService} from './services/Subscriptions.service';
import sweetAlert from "sweetalert2";
import {gralHook} from "./Hooks/gralHook";

const {getAlert} = gralHook();
const subscriber = ref({
    name: '',
    phone: ''
});
const save_subscription = async () => {

/*    let resp = await subscriptionsService.saveOne(subscriber.value);
    getAlert(resp.process);*/
    try {
         const response = await axios.post('/subscription', subscriber).then(response => response.data).catch(err => err.message);
         //console.log(response);
         subscriber.value.name = '';
         subscriber.value.phone = '';
         if (response.process === 'saved') {
             await sweetAlert.fire({
                 title: 'Gracias por suscribirte',
                 text: 'Te mantendremos informado de nuestras promociones y novedades',
                 icon: 'success'
             });
             subscriber.name = '';
             subscriber.phone = '';
         } else {
             await sweetAlert.fire({
                 title: 'Error',
                 text: 'Ocurrió un error al suscribirte, por favor intenta de nuevo',
                 icon: 'error'
             });
         }
     } catch (error) {
         //console.error(error);
     }
};
</script>

<template>

    <form action="" class="footer-newsletter__form">
        <p>
            <label class="sr-only" for="footer-newsletter-address">Nombre</label>
            <input type="text" class="footer-newsletter__form-input form-control"
                   id="footer-newsletter-address" v-model="subscriber.name" placeholder="Nombre">

        </p>
        <p>
            <label class="sr-only" for="footer-newsletter-address">Teléfono Móvil</label>
            <input type="text" class="footer-newsletter__form-input form-control"
                   id="footer-newsletter-address" v-model="subscriber.phone" placeholder="Whatsapp">
        </p>
        <button type="button" class="footer-newsletter__form-button btn btn-primary" @click="save_subscription()">
            Suscríbete
        </button>
    </form>

</template>

<style scoped>
.footer-newsletter__form {
    display: flex;
    flex-direction: column;
}

.footer-newsletter__form p {
    width: 100%;
}

.footer-newsletter__form-button {
    margin: 0;
}
</style>
