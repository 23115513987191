<script setup>
import {ref, onMounted} from 'vue';
import {usersService} from "../../../services/Users.service";
import {rolesService} from "../../../services/Roles.service";
import {gralHook} from "../../../Hooks/gralHook.js";
import sweetAlert from "sweetalert2";
//DATA
const {getAlert, valFormularios} = gralHook();

const vModel = ref({
    name: '',
    email: '',
    phone: '',
    role_id: 0,
    password: '',
});

const params = ref({
    where: [{
        field: 'id',
        operator: '!=',
        value: 5
    }]
});

const paramsUsers = ref({
    where: [{
        field: 'role_id',
        operator: '!=',
        value: 5
    }]
});

const users = ref([]);
const roles = ref([]);
const roleSelected = ref(0);
const modificar = ref(false);

//METHODS
const onDelete = async (id) => {
    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await usersService.deleteOne(id);
            getAlert(resp.process);
            await onIndex();
        }
    })
};

const onUpdate = (data = {}) => {
    modificar.value = true;
    vModel.value = data;
};

const onSave = async () => {
    let resp;
    resp = valFormularios('crudForm');

    if (resp) {
        if (modificar.value) {
            resp = await usersService.updateOne(vModel.value.id, vModel.value);
        } else {
            resp = await usersService.saveOne(vModel.value);
        }
        getAlert(resp.process, resp.error);
    }
};

const onIndex = async () => {
    const rolesResult = await rolesService.getMany(params.value);
    roles.value = Array.isArray(rolesResult) ? rolesResult : [];

   await loadUsers(paramsUsers.value);
};

const loadUsers = async (params) => {
    const usersResult = await usersService.getMany(params);
    users.value = Array.isArray(usersResult) ? usersResult : [];
};

const filterUsers = async () => {
    if (roleSelected.value > 0) {
        params.value.where = [{
            field: 'role_id',
            operator: '=',
            value: roleSelected.value
        }];
    } else {
        params.value.where = [{
            field: 'id',
            operator: '!=',
            value: 5
        }];
    }
    await onIndex();
};

onMounted(async () => {
    await onIndex();
});


</script>

<template>
    <div id="add_user_component">
        <!-- ============ Body content start ============= -->
        <div class="main-content">
            <div class="breadcrumb">
                <h1>Administradores de la Tienda</h1>
                <ul>
                    <li><a href="">Detalle</a></li>
                    <li><!--{{trade-name}}--></li>
                </ul>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
            <section class="contact-list">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="card mb-5">
                            <div class="card-body">
                                <div class="row" style="padding-left: 20px;"> Filtros</div>
                                <div class="row row-xs my-3">
                                    <div class="col-md-10 mt-3 mt-md-0">
                                        <div class="form-group">
                                            <select data-select="on" class="form-control required"
                                                    v-model="roleSelected" @change="filterUsers()">
                                                <option :value="0">Seleccionar</option>
                                                <option v-for="role in roles" :key="role.id" :value="role.id">
                                                    {{ role.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 mt-3 mt-md-0">
                                        <button class="btn btn-primary btn-block">Mostrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <div class="card text-left">
                            <div class="card-header text-right bg-transparent">
                                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                                        data-target="#add-user" v-if="$hasPermission('users.create')">
                                    <i class="fa fa-user-tie text-white mr-2"></i>
                                    Agregar Usuario
                                </button>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-striped">
                                    <table class="display table" id="ul-contact-list" style="width:100%">
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Correo Electrónico</th>
                                            <th>Nivel</th>
                                            <th>Teléfono</th>
                                            <th>Acciones</th>
                                            <th>Estatus</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="user in users" :key="user.id">
                                            <td>{{ user.name }}</td>
                                            <td><i class="fa fa-envelope"></i>{{ user.email }}</td>
                                            <td>{{ user.role_name }}</td>
                                            <td><a><i class="fa fa-phone"></i>{{ user.phone }}</a></td>
                                            <td>
                                                <!-- BOTÓN MODAL-->
                                                <button class="btn btn-warning btn-md m-1" type="button"
                                                        data-toggle="modal"
                                                        data-target="#add-user" @click="onUpdate(user)" v-if="$hasPermission('users.edit')"
                                                        title="Editar Usuario"><i class="fa fa-pencil text-white"></i>
                                                </button>
                                                <!-- BOTÓN MODAL QUITAR -->
                                                <button class="btn btn-danger btn-md m-1" type="button"
                                                        @click="onDelete(user.id)" v-if="$hasPermission('users.delete')"
                                                        title="Eliminar Usuario"><i class="fa fa-ban text-white"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <span v-if="user.delete_at==null" class="text-success">Activo</span>
                                                <span v-else class="text-danger">Inacivo</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- end of main-content -->
            <!-- end of main-content -->
        </div>
        <!--- MODALS RESPONSABLES--->
        <!-- begin::modal ADD USER-->
        <div id="add-user" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Usuario</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="crudForm" class="crudForm">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Nombre Completo:</label>
                                        <input class="form-control required" v-model="vModel.name" type="text"
                                               placeholder="Nombre del Usuario"/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Correo Electrónico:</label>
                                        <input class="form-control required" v-model="vModel.email" type="text"
                                               placeholder="e Mail del Usuario"/>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Teléfono Móvil:</label>
                                        <input class="form-control required" v-model="vModel.phone" type="phone"
                                               placeholder="Número Celular"/>
                                        <small class="ul-form__text form-text" id="">
                                            Número a 10 digitos (Sólo números de México)
                                        </small>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Nivel de Usuario:</label>
                                        <div class="input-right-icon">
                                            <select data-select="on" class="form-control required"
                                                    v-model="vModel.role_id">
                                                <option :value="0">Seleccionar</option>
                                                <option v-for="role in roles" :key="role.id" :value="role.id">
                                                    {{ role.name }}
                                                </option>
                                            </select>
                                            <span class="span-right-input-icon">
                                                <i class="ul-form__icon fas fa-user-tie"></i>
                                            </span>
                                            <small class="ul-form__text form-text" id="">
                                                Seleccione una jerarquía para este nuevo adminsitrador
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Contraseña:</label>
                                        <input class="form-control" :class="(!modificar)?'required':''" v-model="vModel.password" type="text"
                                               placeholder="Contraseña"/>
                                        <small class="ul-form__text form-text" id="">
                                            Asigne una contrasñea a su usuario, si la olvida podrá reestablecerla desde
                                            este modulo
                                        </small>
                                    </div>
                                </div>


                            </div>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal ADD user-->
    </div>
</template>

<style scoped>

</style>
