<script setup>

</script>

<template>
    <div class="main-content">
        <div class="breadcrumb">
            <h1 class="mr-2">Detalle del Producto</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

        <home_slider></home_slider>

        <differentiators></differentiators>

        <feature_products></feature_products>

        <banner_fijo></banner_fijo>

        <productos_destacados_por_categorias></productos_destacados_por_categorias>

        <informacion_relevante></informacion_relevante>

        <!-- end of main-content -->
    </div>
</template>

<style scoped>

</style>
