<script setup>
import {ref, toRef, onMounted} from 'vue';
import {ordersService} from "../../../services/Orders.service";
import {gralHook} from "../../../Hooks/gralHook";

const {getAlert} = gralHook();
const props = defineProps({
    orderId: {
        type: [Number],
        default: null,
    },
});
const status_order = ref(0);
const order_id = toRef(props, 'orderId');
const changeStatusOrder = async () => {
    let resp = await ordersService.changeStatusOrder(order_id.value, {'status_order': status_order.value});
    getAlert(resp.process, true);
};

</script>

<template>
    <!-- begin::modal CHANGE Status-->
    <div id="order-status" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Estatus del Pedido</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <!-- begin::form-->
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label class="ul-form__label" for="">Seleccione el nuevo estatus del Pedido:</label>
                                <select class="form-control" v-model="status_order">

                                        <option :value="0">Todas</option>
                                        <option :value="1">Pendientes de Pago</option>
                                        <option :value="2">En Revisión de Pago</option>
                                        <option :value="3">Preparando Pedido</option>
                                        <option :value="4">Listo para Enviar</option>
                                        <option :value="5">Enviado</option>
                                        <option :value="6">Anulado</option>

                                </select>
                            </div>
                        </div>
                    </form>
                    <!--  end::form -->
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                    <button class="btn btn-success ml-2" type="button" @click="changeStatusOrder()">Actualizar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- end::modal CHANGE Status-->
</template>

<style scoped>

</style>
