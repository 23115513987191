<script setup>
import {ref, watch, watchEffect} from "vue";
import {accountService} from "../../../services/Account.service";
import {ordersService} from "../../../services/Orders.service";
import {gralHook} from "../../../Hooks/gralHook";

const {getAlert} = gralHook();
const props = defineProps({
    clientId: {
        type: [Number],
        default: null,
    },
    orderId: {
        type: [Number],
        default: null,
    },
});

const order_id = ref(0);
const client_id = ref(0);
const address = ref([]);
const selectedAddress = ref(null); // Añade esta línea
const selectedAddressId = ref(0); // Añade esta línea
const onIndex = async () => {
    address.value = await accountService.getAddressByClient(client_id.value);
};

const loadAddressSelected = (event) => { // Añade el parámetro event
    console.log(event.target.value, address.value);
    const selectedId = Number(event.target.value); // Convierte selectedId a un número
    selectedAddress.value = address.value.find(add => add.id == selectedId);
    console.log(selectedAddress.value);
    selectedAddressId.value = selectedId;
};

const updateAddressOrder = async () => {
    //console.log(selectedAddressId.value, order_id.value);
    let rest = await ordersService.changeAddressOrder(selectedAddressId.value, order_id.value);
    getAlert(rest.process, true);
};

watchEffect(async () => {
    order_id.value = props.orderId;
    client_id.value = props.clientId;
    await onIndex();
});

</script>

<template>
    <!-- begin::modal CHANGE Address-->
    <div id="order-address" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Dirección de Envío</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <!-- begin::form-->
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label class="ul-form__label" for="">Desea cambiar la dirección del Pedido:</label>
                                <select class="form-control" @change="loadAddressSelected">
                                    <option value="">Seleccione una dirección</option>
                                    <option v-for="add in address" :key="add.id" :value="add.id">
                                        {{ add.identifier }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <!--  end::form -->
                    <!--  start card -->
                    <p v-if="selectedAddress"><strong> Dirección: </strong>{{ selectedAddress.address }}</p>
                    <p v-if="selectedAddress"><strong> Colonia: </strong>{{ selectedAddress.neighborhood }}</p>
                    <p v-if="selectedAddress"><strong> Código Postal: </strong>{{ selectedAddress.zip_code }}</p>
                    <p v-if="selectedAddress"><strong> Ciudad / Estado: </strong>{{ selectedAddress.rel_state.estado }}
                    </p>
                    <p v-if="selectedAddress"><strong> Recibe: </strong>{{ selectedAddress.rel_city.ciudad }}</p>
                    <p v-if="selectedAddress"><strong> Tel: </strong>{{ selectedAddress.contact_phone }}</p>

                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                    <button class="btn btn-success ml-2" type="button" @click="updateAddressOrder">Actualizar</button>

                </div>
            </div>
        </div>
    </div>
    <!-- end::modal CHANGE Address-->
</template>

<style scoped>

</style>
