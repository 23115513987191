<script setup>
import {ref, onMounted, watch, reactive} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import sweetAlert from "sweetalert2";
import {categoriesService} from "../../../services/Categories.service";

const {getAlert, valFormularios, goBack, generateFriendlyUrl, getFormData} = gralHook();
let forms = ref([{name: '', url: '', image: null, imageName: 'Elegir Imagen'}]);
const cats = ref([]);
const vModel = ref({
    id: '',
    name: '',
    friendly_url: '',
});
const modificar = ref(false);
const draggedItem = ref(null);
const items = ref([]);
const dragAndDropEnabled = ref(false);
const trashList = ref(false);

const addForm = () => {
    const newForm = reactive({name: '', url: '', image: null});
    watch(() => newForm.name, (newVal) => {
        newForm.url = generateFriendlyUrl(newVal);
    });
    forms.value.push(newForm);
};

// Inicializar el watch para los formularios existentes
forms.value.forEach(form => {
    watch(() => form.name, (newVal) => {
        form.url = generateFriendlyUrl(newVal);
    });
});

const onRestore = async (id) => {
    let resp;
    resp = await categoriesService.restoreTrash(id);
    getAlert(resp.process);
};

const onDelete = async (id) => {

    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp;
            resp = await categoriesService.deleteOne(id);
            getAlert(resp.process);
        }
    })

};

const onSave = async () => {
    let resp;
    let formData = new FormData();

    for (let index = 0; index < forms.value.length; index++) {
        const form = forms.value[index];

        if (!form.name || form.name.trim() === '') {
            console.error(`El campo nombre del formulario ${index} es requerido`);
            return false;
        }

        if (form.image && form.image instanceof File) {
            formData.append(`image${index}`, form.image, form.image.name);
        } else {
            console.error(`La imagen del formulario ${index} no es válida`);
            return false;
        }

        formData.append(`name${index}`, form.name);
        formData.append(`url${index}`, form.url);
    }

    if (modificar.value) {
        resp = await categoriesService.updateOne(vModel.value.id, formData, {isForm: true});
    } else {
        resp = await categoriesService.saveOne(formData, {isForm: true});
    }
    getAlert(resp.process);
};

const onUpdate = (data) => {
    vModel.value = data;
    modificar.value = true;
};

const validateImage = (event, form) => {
    let file = event.target.files[0];
    let img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = function () {
        let width = this.width;
        let height = this.height;
        if (width > 1000 && height > 1000) {
            alert('Las dimensiones de la imagen no son correctas.');
        } else {
            form.image = file;
            form.imageName = file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name;
        }
    };
}

const removeForm = (index) => {
    this.forms.splice(index, 1);
}

const onIndex = async () => {
    cats.value = await categoriesService.getMany();
};

//METODOS DE DRAG AND DROP

const enableDragAndDrop = () => {
    dragAndDropEnabled.value = !dragAndDropEnabled.value;
    const items = document.querySelectorAll('[draggable]');
    items.forEach(item => {
        item.setAttribute('draggable', true);
    });
};

const handleDragStart = (index) => {
    //console.log('handleDragStart', index);
    draggedItem.value = index;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDrop = (index) => {
    const droppedItem = cats.value[draggedItem.value];
    cats.value = cats.value.filter((item, i) => i !== draggedItem.value);
    cats.value.splice(index, 0, droppedItem);
    draggedItem.value = null;
    //console.log('fieldsDrop', fields.value);
    // Actualizar el orden de los elementos en el array fields
    cats.value = cats.value.map((field, index) => ({...field, order: index}));
};

const handleDragEnd = async () => {
    draggedItem.value = null;
    await updateOrder();
};

const updateOrder = async () => {
    const data = {
        category_fields: cats.value
    }
    let resp = await categoriesService.updateOrder(data);
    getAlert(resp.process);
};

const filterTrash = async () => {
    if (document.querySelector('.trashFilter').value == 1) {
        await onIndex();
        trashList.value = false;
    } else {
        trashList.value = true;
        cats.value = await categoriesService.filterTrash();
    }
};


onMounted(async () => {
    await onIndex();
});
</script>

<template>
    <div id="add_cat_component">
        <!-- begin::modal ADD Category-->
        <div id="add-category" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Categorías Masivamente</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->

                        <div class="card-body">
                            <div><p class="text-danger">No usar carácteres epeciales en la <strong>URL
                                Amigable</strong>, SIN carácteres especiales (, . + / & % ´´ ), sólo palabras sin
                                acentos </p></div>


                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <!-- Este label va en Disabled al editar y debe ser irrepetible-->
                                    <label class="ul-form__label" for="">Nombre:</label>
                                </div>
                                <div class="form-group col-md-3">
                                    <!-- Este label va en Disabled al editar y debe ser irrepetible-->
                                    <label class="ul-form__label" for="">URL Amigable:</label>
                                </div>
                                <div class="form-group col-md-5">
                                    <label class="ul-form__label" for="" style="padding-left: 18px;">Imagen:</label>
                                </div>
                                <div class="form-group col-md-1">
                                    <button class="btn btn-success ml-2" type="button" v-if="!modificar"
                                            @click="addForm">
                                        <i class="fa fa-plus"></i></button>
                                </div>
                            </div>

                            <!-- ITEM Start-->
                            <template v-for="(form, index) in forms" :key="index">
                                <form :id="'formCrud_' + index" :class="'formCrud_' + index + ' form-row'">
                                    <div class="form-group col-md-3">
                                        <input class="form-control required" v-model="form.name" type="text"
                                               placeholder="Nombre"/>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <input class="form-control" readonly v-model="form.url" type="text"
                                               placeholder="Puede llevar guiones"/>
                                    </div>
                                    <div class="form-group col-md-5">
                                        <div class="input-group col-md-12">
                                            <div class="input-group-prepend"><span
                                                class="input-group-text">Cargar</span></div>
                                            <div class="custom-file">
                                                <input class="custom-file-input" type="file"
                                                       @change="validateImage($event, form)"/>
                                                <label class="custom-file-label">{{ form.imageName }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-1">
                                        <button class="btn btn-danger ml-2" type="button" @click="removeForm(index)"><i
                                            class="fa fa-times"></i></button>
                                    </div>
                                </form>
                            </template>
                            <!-- Item End-->


                            <div><p class="text-danger my-4">No usar carácteres epeciales en la <strong>URL
                                Amigable</strong>, SIN carácteres especiales (, . + / & % ´´ ), sólo palabras sin
                                acentos </p></div>
                        </div>

                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave">Añadir</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal ADD Category-->
        <div class="main-content">
            <div class="breadcrumb">
                <h1 class="mr-2">Categorias</h1>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="card mb-5">
                        <div class="card-body">
                            <div class="row" style="padding-left: 20px;"> Filtros</div>
                            <div class="row row-xs my-3">
                                <div class="col-md-10 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <select class="form-control trashFilter" @change="filterTrash()">
                                            <option :value="1">Activas</option>
                                            <option :value="2">Eliminadas</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-3 mt-md-0">
                                    <button class="btn btn-primary btn-block">Mostrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="contact-list">
                <div class="row">
                    <div class="col-md-8 mb-4">
                        <div class="card text-left">
                            <div class="card-header text-right bg-transparent">
                                <button class="btn btn-warning btn-md m-1" type="button" @click="enableDragAndDrop()" v-if="$hasPermission('categories.reorder')">
                                    <i class="fa fa-sort-amount-down mr-2"></i>
                                    Reordenar
                                </button>
                                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal" data-target="#add-category" v-if="$hasPermission('categories.create')">
                                    <i class="fa fa-plus text-white mr-2"></i>
                                    Agregar Categoría
                                </button>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-striped">
                                    <table class="display table" id="ul-contact-list" style="width:100%">
                                        <thead>
                                        <tr>
                                            <th v-if="dragAndDropEnabled">Arrastrar</th>
                                            <th>ID</th>
                                            <th>Imagen</th>
                                            <th>Nombre</th>
                                            <th>URL Amigable</th>
                                            <th>Acciones</th>
                                            <th>Estatus</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(cat,index) in cats" :key="cat.id">
                                            <!--- Inicia ITEM-->
                                            <tr class="drag"
                                                :data-index="index"
                                                :draggable="false"
                                                @dragstart="handleDragStart(index)"
                                                @dragover.prevent="handleDragOver"
                                                @drop="handleDrop(index)"
                                                @dragend="handleDragEnd"
                                                :class="(dragAndDropEnabled)?'pointerCursor':''">

                                                <td class="form-group col-md-2" v-if="dragAndDropEnabled">
                                                    <i class="fa fa-sort-amount-down"></i>
                                                </td>
                                                <td style="vertical-align: middle !important;">{{ cat.id }}</td>
                                                <td style="vertical-align: middle !important;"><img
                                                    :src="'/images/categories/'+cat.image_home" alt="" width="100px">
                                                </td>
                                                <td style="vertical-align: middle !important;">{{ cat.name }}</td>
                                                <td style="vertical-align: middle !important;">{{
                                                        cat.friendly_url
                                                    }}
                                                </td>
                                                <td style="vertical-align: middle !important;">
                                                    <!-- BOTÓN MODAL Editar -->
                                                    <button v-if="!trashList && $hasPermission('categories.edit')" class="btn btn-warning btn-md m-1"
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target="#add-category"
                                                            title="Editar Categoría" @click="onUpdate(cat)">
                                                        <i class="fa fa-edit text-white"></i>
                                                    </button>
                                                    <!-- BOTÓN MODAL Eliminar  Soft Delete-->
                                                    <button v-if="!trashList && $hasPermission('categories.delete')" class="btn btn-danger btn-md m-1"
                                                            type="button"
                                                            data-toggle="modal" data-target="#delete-category"
                                                            title="Eliminar Categoría" @click="onDelete(cat.id)"><i
                                                        class="fa fa-trash text-white"></i></button>
                                                    <!-- Solo aparece en los Soft Delete BOTÓN MODAL Reactivar  Quitar Soft Delete-->
                                                    <button v-if="trashList && $hasPermission('categories.restore')" class="btn btn-success btn-md m-1"
                                                            type="button"
                                                            data-toggle="modal" data-target="#activate-category"
                                                            title="Reactivar Categoría" @click="onRestore(cat.id)">
                                                        <i class="fa fa-check text-white"></i>
                                                    </button>

                                                </td>
                                                <td class="text-success" style="vertical-align: middle !important;">
                                                    Activa
                                                </td>
                                            </tr>
                                            <!--- Termina Item-->
                                        </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- end of main-content -->
        </div>
    </div>
</template>

<style scoped>
.pointerCursor {
    cursor: pointer;
}
</style>
