import {BaseService} from "./base.service";

class AuthService extends BaseService {
    constructor() {
        super('');
    }

    getUserPermissions() {
        return this.http.get(`api/user-permissions`).then(response => response.data).catch(err => err.message);
    }

    isAuthenticated() {
        // Verificar si las cookies existen
        return document.cookie.includes('F1=') && document.cookie.includes('R3=') && document.cookie.includes('B1rd=');
    }
}

export const authService = new AuthService();
