import {BaseService} from "./base.service";

class VsProductTagService extends BaseService {

    constructor() {
        super('api/vs_product_tags')
    }

}

export const vsproducttagService = new VsProductTagService();
