<template>
    <div class="main-content">
        <div class="breadcrumb">
            <h1 class="mr-2">Asignar Permisos</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
        <div class="row">
            <div class="col-12 col-sm-3 mb-4">
                <select class="form-control" v-model="selectedRole">
                    <option :value="0" disabled>Seleccione un rol</option>
                    <option v-for="role in roles" :key="role.id" :value="role">{{ role.name }}</option>
                </select>
            </div>
            <div class="col-12 col-sm-3">
                <button class="btn btn-primary" v-if="selectedRole!==0" @click="savePermissions">
                    Guardar Permisos
                </button>
                <button class="btn btn-primary ml-3" v-if="selectedRole!==0" @click="toggleAllPermissions">
                    Seleccionar todo
                </button>
            </div>
        </div>

        <div class="row">
            <div v-for="category in uniqueCategories" :key="category" class="col-12 col-sm-3">
                <div class="card mb-5">
                    <div class="card-body">
                        <div>
                            <h5>{{ category }}</h5>
                            <hr class="mt-1 mb-2">
                        </div>
                        <div class="row row-xs my-3">
                            <div class="col-md-12 mt-3 mt-md-0">
                                <div class="row justify-content-center">
                                    <div class="col-3" v-for="permission in getPermissionsByCategory(category)"
                                         :key="permission.id">
                                        <input
                                            type="checkbox"
                                            :id="'permission-' + permission.id"
                                            :checked="roleHasPermission(selectedRole, permission)"
                                            @change="togglePermission(selectedRole, permission)"
                                        />
                                        <label :for="'permission-' + permission.id" class="ml-1">
                                            {{ permission.alias }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-3">
                <button class="btn btn-primary" v-if="selectedRole!==0" @click="savePermissions">
                    Guardar Permisos
                </button>
                <button class="btn btn-primary ml-3" v-if="selectedRole!==0" @click="toggleAllPermissions">
                    Seleccionar todo
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue';
import {rolesService} from '../../../services/Roles.service';
import {permissionsService} from '../../../services/Permissions.service';
import {gralHook} from "../../../Hooks/gralHook.js";

const {getAlert} = gralHook();
const roles = ref([]);
const permissions = ref([]);
const selectedRole = ref(0);

//METHODS
const fetchRolesAndPermissions = async () => {
    try {
        roles.value = await rolesService.getMany();
        permissions.value = await permissionsService.getMany();
    } catch (error) {
        console.error('Error fetching roles and permissions:', error);
    }
};

const uniqueCategories = computed(() => {
    const categories = permissions.value.map(permission => permission.category);
    return [...new Set(categories)];
});

const getPermissionsByCategory = (category) => {
    return permissions.value.filter(permission => permission.category === category);
};

const roleHasPermission = (role, permission) => {
    if (!role || !role.permissions) return false;
    return role.permissions.some(rolePermission => rolePermission.id === permission.id);
};

const togglePermission = (role, permission) => {
    if (!role || !role.permissions) return;
    const permissionIndex = role.permissions.findIndex(rolePermission => rolePermission.id === permission.id);
    if (permissionIndex > -1) {
        role.permissions.splice(permissionIndex, 1);
    } else {
        role.permissions.push(permission);
    }
};

const toggleAllPermissions = () => {
    if (!selectedRole.value) return;
    const allPermissions = permissions.value;
    const allSelected = allPermissions.every(permission => roleHasPermission(selectedRole.value, permission));
    if (allSelected) {
        // Deseleccionar todos los permisos
        allPermissions.forEach(permission => {
            const permissionIndex = selectedRole.value.permissions.findIndex(rolePermission => rolePermission.id === permission.id);
            if (permissionIndex > -1) {
                selectedRole.value.permissions.splice(permissionIndex, 1);
            }
        });
    } else {
        // Seleccionar todos los permisos
        allPermissions.forEach(permission => {
            const permissionIndex = selectedRole.value.permissions.findIndex(rolePermission => rolePermission.id === permission.id);
            if (permissionIndex === -1) {
                selectedRole.value.permissions.push(permission);
            }
        });
    }
};

const savePermissions = async () => {
    let resp = '';
    if (selectedRole.value) {
        try {
            const permissionIds = selectedRole.value.permissions.map(permission => permission.id);
            resp = await permissionsService.updateRolePermissions(selectedRole.value.id, {permissions: permissionIds});
            console.log('resp save Permisos=', resp)
            getAlert(resp.process);
        } catch (error) {
            console.error('Error saving permissions:', error);
        }
    }
};

onMounted(fetchRolesAndPermissions);

</script>

<style scoped>
.card {
    max-height: 400px;
    overflow-y: auto;
}
</style>
